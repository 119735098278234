import React, { useContext, useEffect, useRef, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { MdSearch } from "react-icons/md";
import { IoVideocam } from "react-icons/io5";
import { GoArrowLeft } from "react-icons/go";
import Button from "./Common/RoundedBtn";
import Dropdown from "./Dropdown";
import { MdAddCall } from "react-icons/md";
import { FaRegStar, FaStar } from "react-icons/fa6";
import CallingPopup from "./CallingPopup";
//import io from "socket.io-client";
import { imgUrl } from "../utils/services";
import { AuthContext } from "../context/AuthContext";
import { useSocketContext } from "../context/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import IncomingCall from "./IncomingCall";
import { postRequest } from "../utils/services";
import { ConversationContext } from "../context/ConversationContext";
import { setSelectedChat } from '../redux/conversationSlice';
const ChatHeader = ({
  selectedChat,
  onProfileClick,
  setShowSearchPopup,
  onClearChat,
  isMobile,
  onBackClick,
  onStarredMessagesToggle,
}) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCallingPopup, setShowCallingPopup] = useState(false);
  const [isVideoCall, setIsVideoCall] = useState(false);
  const [isFilteringFavorites, setIsFilteringFavorites] = useState(false);
  const [mediaElement, setMediaElement] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [ongoingCallChatId, setOngoingCallChatId] = useState(null);
  const dropdownRef = useRef(null);
  const [showVideoToggle, setShowVideoToggle] = useState(false);
  //const [onlineUsers, setOnlineUsers] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { onlineUsers, socket } = useSelector((state) => state.socket);

  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };
  // console.log("Data From Chat Header:", selectedChat);
  const userConversations = useSelector((state) => state.conversation.userConversations);

  const handleChatSelect = (chat) => {
    dispatch(setSelectedChat(chat));
  };
  const handleBlockUnblock = async (action, convo) => {
    try {
      if (action === "block") {
        const userToBlockId = convo.participants.find(
          (participant) => participant._id !== user._id
        )._id;

        const response = await postRequest("/conversations/block", {
          conversationId: convo._id,
          userToBlockId,
        });

        if (!response.error) {
          console.log(`Blocked User in Chat _id: ${convo._id}`);
          convo.blockedBy = convo.blockedBy || [];
          convo.blockedBy.push({ blockedBy: user._id });
          handleChatSelect(convo);
        }
      } else if (action === "unblock") {
        const userToUnblockId = convo.participants.find(
          (participant) => participant._id !== user._id
        )._id;

        const response = await postRequest("/conversations/unblock", {
          conversationId: convo._id,
          userToUnblockId,
        });

        if (!response.error) {
          console.log(`Unblocked User in Chat _id: ${convo._id}`);
          convo.blockedBy = convo.blockedBy.filter(
            (block) => block.blockedBy !== user._id
          );
          handleChatSelect(convo);
        }
      }
    } catch (error) {
      console.error(`Error during ${action}:`, error.message);
    }
  };

  const onBlockContact = () => {
    handleBlockUnblock("block", selectedChat);
  };

  const onUnblockContact = () => {
    handleBlockUnblock("unblock", selectedChat);
  };

  const handleFavoriteFilterToggle = () => {
    setIsFilteringFavorites((prev) => !prev);
    onStarredMessagesToggle();
  };

  const otherParticipant = selectedChat?.participants.find(
    (participant) => participant._id !== user?._id
  );
  const lastSeenData = onlineUsers.find((u) => u.id === otherParticipant?._id);
  const isOnline = lastSeenData && lastSeenData.lastSeen === null;
  const lastSeen = lastSeenData ? lastSeenData.lastSeen : null;

  //const isOnline = onlineUsers.some((u) => u.id === otherParticipant?._id);
  //const lastSeen = onlineUsers.find((u) => u.id === otherParticipant?._id)?.lastSeen;

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
  // console.log("Online Users:", onlineUsers);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  const handleVideoCall = () => {
    setShowCallingPopup(true);
    setOngoingCallChatId(selectedChat._id);
    setIsVideoCall(true);
    setShowVideoToggle(true);
    selectedChat.callType = "video";
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        stream.getAudioTracks().forEach((track) => (track.enabled = false));
        const video = document.createElement("video");
        video.srcObject = stream;
        video.play();
        document.body.appendChild(video);
        video.style.position = "fixed";
        video.style.bottom = "20px";
        video.style.right = "20px";
        video.style.width = "200px";
        video.style.height = "150px";
        video.style.zIndex = "100";
        setMediaElement(video);

        socket.emit("startCall", {
          from: user._id,
          to: otherParticipant._id,
          callType: "video",
        });
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });
  };

  const handleCall = () => {
    setShowCallingPopup(true);
    setOngoingCallChatId(selectedChat._id);
    setIsVideoCall(false);
    selectedChat.callType = "audio";
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        stream.getAudioTracks().forEach((track) => (track.enabled = false));
        const audio = document.createElement("audio");
        audio.srcObject = stream;
        audio.play();
        setMediaElement(audio);

        socket.emit("startCall", {
          from: user._id,
          to: otherParticipant._id,
          callType: "audio",
        });
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const handleEndCall = () => {
    if (mediaElement) {
      const stream = mediaElement.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      mediaElement.remove();
    }
    setShowCallingPopup(false);
    setOngoingCallChatId(null);
    setMediaElement(null);
    setShowVideoToggle(false);

    socket.emit("endCall", {
      from: user._id,
      to: otherParticipant._id,
    });
  };

  useEffect(() => {
    if (ongoingCallChatId === selectedChat._id) {
      setShowCallingPopup(true);
    } else {
      setShowCallingPopup(false);
    }
  }, [selectedChat._id, ongoingCallChatId]);

  const toggleVideo = () => {
    if (mediaElement && mediaElement.srcObject) {
      const videoTracks = mediaElement.srcObject.getVideoTracks();
      if (videoTracks.length > 0) {
        const videoTrack = videoTracks[0];
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoCall(videoTrack.enabled);
      } else {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            const videoTrack = stream.getVideoTracks()[0];
            mediaElement.srcObject.addTrack(videoTrack);
            videoTrack.enabled = true;
            setIsVideoCall(true);

            const video = document.createElement("video");
            video.srcObject = stream;
            video.play();
            document.body.appendChild(video);
            video.style.position = "fixed";
            video.style.bottom = "20px";
            video.style.right = "20px";
            video.style.width = "200px";
            video.style.height = "150px";
            video.style.zIndex = "100";
            setMediaElement(video);
          })
          .catch((error) => {
            console.error("Error accessing camera:", error);
          });
      }
    }
  };
  const handleAcceptCall = () => {
    setShowCallingPopup(true);
    setIncomingCall(null);

    if (incomingCall.callType === "video") {
      handleVideoCall();
    } else {
      handleCall();
    }
  };
  const onlineMembers = selectedChat?.isGroupChat
    ? selectedChat?.participant?.filter((participant) =>
      onlineUsers.some((u) => u._id === participant._id)
    ) || []
    : [];
  const isBlocked = selectedChat?.blockedBy?.some(
    (block) => block.blockedBy === user?._id
  );
  return (
    <div className="relative -mb-6 z-10">
      <div className="flex justify-between bg-[#202d33] h-[60px] p-3">
        <div className="flex items-center">
          {/* Back Button for Mobile */}
          {isMobile && (
            <button onClick={onBackClick} className="mr-3">
              <GoArrowLeft className="text-white text-2xl" />
            </button>
          )}

          <div
            className="flex items-center cursor-pointer"
            onClick={onProfileClick}
          >
            <img
              src={`${imgUrl}${selectedChat?.chatIcon}`}
              alt="profile_picture"
              className="rounded-full w-[45px] h-[45px] mr-5"
            />
            <div className="flex flex-col">
              <h1 className="text-white font-medium truncate max-w-[200px]">
                {selectedChat?.chatName}
              </h1>

              <p className="text-[#b6b8b8] text-xs flex flex-wrap gap-1 overflow-hidden">
                {selectedChat?.isGroupChat
                  ? (() => {

                    const MAX_WIDTH = isMobile ? 400 : 800;
                    let displayedNames = [];
                    let totalWidth = 0;

                    const ellipsisWidth = 10;
                    const remainingWidth = MAX_WIDTH - ellipsisWidth;

                    for (const participant of onlineMembers.length
                      ? onlineMembers
                      : selectedChat?.participants || []) {
                      const participantName = participant.username;
                      const nameWidth = participantName.length * 30;

                      if (totalWidth + nameWidth <= remainingWidth) {
                        displayedNames.push(participantName);
                        totalWidth += nameWidth;
                      } else {
                        break;
                      }
                    }

                    const remainingCount =
                      (onlineMembers.length
                        ? onlineMembers.length
                        : selectedChat?.participants?.length || 0) -
                      displayedNames.length;

                    return (
                      <>
                        {displayedNames.join(", ")}
                        {remainingCount > 0 && `, ... (${remainingCount} more)`}
                      </>
                    );
                  })()
                  : isOnline
                    ? "Online"
                    : `Last seen: ${lastSeen
                      ? new Date(lastSeen).toLocaleString()
                      : "Long time ago"
                    }`}
              </p>

            </div>
          </div>
        </div>

        <div className="flex items-center space-x-0">
          {!isMobile && (
            <>
              <Button icon={<MdAddCall />} onClick={handleCall} />
              <Button icon={<IoVideocam />} onClick={handleVideoCall} />
              <Button
                icon={<MdSearch />}
                onClick={() => setShowSearchPopup(true)}
              />

              <Button
                icon={isFilteringFavorites ? <FaStar /> : <FaRegStar />}
                onClick={handleFavoriteFilterToggle}
              />
            </>
          )}
          <Button icon={<HiDotsVertical />} onClick={handleDropdownToggle} />
          {showDropdown && (
            <div className="absolute top-[60px] right-0 z-50" ref={dropdownRef}>
              <Dropdown
                onClearChat={onClearChat}
                onBlockContact={onBlockContact}
                onUnblockContact={onUnblockContact}
                isBlocked={isBlocked}
                options={[
                  ...(isMobile
                    ? [
                      { label: "Call", onClick: handleCall },
                      { label: "Video Call", onClick: handleVideoCall },
                      {
                        label: "Search",
                        onClick: () => setShowSearchPopup(true),
                      },
                    ]
                    : []),
                  { label: "Clear Chat", onClick: onClearChat },
                  ...(selectedChat?.isGroupChat === false
                    ? isBlocked
                      ? [
                        {
                          label: "Unblock Contact",
                          onClick: onUnblockContact,
                        },
                      ]
                      : [{ label: "Block Contact", onClick: onBlockContact }]
                    : []),
                ]}
              />
            </div>
          )}
        </div>
      </div>



      {showCallingPopup && ongoingCallChatId === selectedChat._id && (
        <CallingPopup
          selectedChat={selectedChat}
          onEndCall={handleEndCall}
          isVideoCall={isVideoCall}
          mediaElement={mediaElement}
          toggleVideo={toggleVideo}
          showVideoToggle={showVideoToggle}
        />
      )}
      {incomingCall && incomingCall.callerId !== user?._id && (
        <IncomingCall
          incomingVoiceCall={incomingCall}
          onAnswer={handleAcceptCall}
          onReject={() => setIncomingCall(null)}
        />
      )}
    </div>
  );
};
export default ChatHeader;
