import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../utils/services";

export const addMembersToGroup = createAsyncThunk(
  "group/addMembersToGroup",
  async ({ conversationId, newMembers, requesterId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/conversations/addMembers`,
        { conversationId, newMembers, requesterId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateGroupDescription = createAsyncThunk(
  "group/updateGroupDescription",
  async ({ conversationId, description,requesterId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${baseUrl}/conversations/updateDescription`,
        { conversationId, description,requesterId},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const removeUserFromGroup = createAsyncThunk(
    "group/removeUser",
    async ({ conversationId, membersToRemove, requesterId}, { rejectWithValue }) => {
      try {
        const response = await axios.post(
          `${baseUrl}/conversations/removeMembers`,
          {
            conversationId,
            membersToRemove,
            requesterId,
          },
          {
            headers: {
                'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        return { membersToRemove };
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );
  
  export const exitGroup = createAsyncThunk(
    "group/exitGroup",
    async ({ conversationId, userId, participants, groupAdmins,requesterId,}, { dispatch, rejectWithValue }) => {
      try {
        let isLastAdmin = groupAdmins.length === 1 && groupAdmins.includes(userId);
        let newAdminId = null;
  
        if (isLastAdmin && participants.length > 1) {
          const remainingParticipants = participants.filter((participant) => participant._id !== userId);
          if (remainingParticipants.length > 0) {
            const randomIndex = Math.floor(Math.random() * remainingParticipants.length);
            newAdminId = remainingParticipants[randomIndex]._id;
  
            dispatch(setGroupAdmins([newAdminId]));
          }
        }
  
        const response = await axios.post(
          `${baseUrl}/conversations/leave`,
          { conversationId, userId },
          {
            headers: {
                'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (newAdminId) {
          dispatch(
            promoteToAdmins({
              conversationId,
              userId: newAdminId,
              requesterId,
            })
          );
        }
  
        return { userId, newAdminId };
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );
  
  export const removeAdmins = createAsyncThunk(
    "group/removeAdmin",
    async ({ conversationId, adminId, requesterId}, { rejectWithValue }) => {
      try {
        const response = await axios.post(
          `${baseUrl}/conversations/removeAdmin`,
          {
            conversationId,
            adminId,
            requesterId,
          },
          {
            headers: {
                'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
  
        if (response.data.success) {
          return { adminId };
        } else {
          throw new Error(response.data.error || "Failed to remove admin");
        }
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );
  
  export const promoteToAdmins = createAsyncThunk(
    "group/promoteToAdmin",
    async ({ conversationId, adminIds, requesterId }, { rejectWithValue }) => {
      try {
        const response = await axios.post(
          `${baseUrl}/conversations/assignAdmins`,
          {
            conversationId,
            adminIds,
            requesterId,
          },
          {
            headers: {
                'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
  
        return {  adminIds };
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );

  export const updateChatIcon = createAsyncThunk(
    "group/updateChatIcon",
    async ({ conversationId, newIcon }, { rejectWithValue }) => {
      try {
        const formData = new FormData();
        formData.append("chatIcon", newIcon);
  
        const response = await axios.put(
          `${baseUrl}/conversations/updateChatIcon/${conversationId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
  
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  );

const groupSlice = createSlice({
  name: "group",
  initialState: {
    participants: [],
    groupAdmins: [],
    chatIcon: null,
    chatName: null,
    isDesEditing:false,
    description:"",
    error: null,
  },
  reducers: {
    setParticipants(state, action) {
        state.participants = action.payload;
      },
      setGroupAdmins(state, action) {
        state.groupAdmins = action.payload;
      },
      setChatIcon(state, action) {
        state.chatIcon = action.payload;
      },
      setIsDesEditing(state, action) {
        state.isDesEditing = action.payload;
      },
      setDescription(state, action) {
        state.description = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMembersToGroup.fulfilled, (state, action) => {  const updatedParticipants = action.payload.participants;

        const uniqueParticipants = [
          ...new Map(
            [...updatedParticipants, ...state.participants].map((item) => [
              item._id,
              item,
            ])
          ).values(),
        ];

        state.participants = uniqueParticipants;
      })
      .addCase(updateGroupDescription.fulfilled, (state, action) => {
        state.isDesEditing = false;
      })
      .addCase(updateGroupDescription.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(removeUserFromGroup.fulfilled, (state, action) => {
        const { membersToRemove } = action.payload;
        state.participants = state.participants.filter(
          (p) => !membersToRemove.includes(p._id)
        );
        state.groupAdmins = state.groupAdmins.filter(
          (id) => !membersToRemove.includes(id)
        );
      })
      .addCase(removeUserFromGroup.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(exitGroup.fulfilled, (state, action) => {
        const { userId, newAdminId } = action.payload;
        state.participants = state.participants.filter((p) => p._id !== userId);
        if (newAdminId) {
          state.groupAdmins = [newAdminId];
        }
      })
      .addCase(removeAdmins.fulfilled, (state, action) => {
        const { adminId } = action.payload;
        state.groupAdmins = state.groupAdmins.filter((admin) => admin !== adminId);
      })
      .addCase(removeAdmins.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(promoteToAdmins.fulfilled, (state, action) => {
        const { adminIds } = action.payload;
        adminIds.forEach((id) => {
          if (!state.groupAdmins.includes(id)) {
            state.groupAdmins.push(id);
          }
        });
      })
      .addCase(promoteToAdmins.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateChatIcon.fulfilled, (state, action) => {
        state.chatIcon = action.payload.chatIcon;
      })
      .addCase(updateChatIcon.rejected, (state, action) => {
        state.error = action.payload;
      })
  },
});

export const { setParticipants, setGroupAdmins, setIsDesEditing,setDescription,setChatIcon } = groupSlice.actions;
export default groupSlice.reducer;
