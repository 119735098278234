import React, { useState, useEffect } from "react";
import { IoCall, IoVideocamOff, IoVideocam } from "react-icons/io5";
import { BsMicMuteFill, BsMic } from "react-icons/bs";
import { imgUrl } from "../utils/services";

const CallingPopup = ({
  selectedChat,
  onEndCall,
  isVideoCall,
  mediaElement,
  toggleVideo,
  showVideoToggle, 
}) => {
  const [isMuted, setIsMuted] = useState(true);
  const [callAccepted, setCallAccepted] = useState(false);

  useEffect(() => {
    if (mediaElement) {
      mediaElement.srcObject
        .getAudioTracks()
        .forEach((track) => (track.enabled = !isMuted));
    }
  }, [isMuted, mediaElement]);

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="border-slate-600 border-l w-full flex flex-col h-[calc(100vh-60px)] bg-slate-950 overflow-hidden items-center justify-center text-white">
      <div className="flex flex-col gap-3 items-center">
        <span className="text-5xl"> {selectedChat?.chatName}</span>
        <span className="text-lg">
          {
            callAccepted && selectedChat.callType !== "video"
              ? "On going call"
              : "Calling"
          }
        </span>

        {(!callAccepted || selectedChat.callType === "audio") && (<div className="my-20">
          <img
            src={`${imgUrl}${selectedChat?.chatIcon}`}
            alt="profile_picture"
            height={300}
            width={300}
            className="rounded-full"
          />
        </div>)}

        <div className="flex justify-center items-center space-x-4">
          <button className="h-16 w-16 bg-red-600 flex items-center justify-center rounded-full" onClick={onEndCall}>
            <IoCall className="text-3xl cursor-pointer" />
          </button>
          <button
            className={`text-2xl ${isMuted ? "text-gray-500" : "text-green-500"
              }`}
            onClick={handleMuteToggle}
          >
            {isMuted ? <BsMicMuteFill /> : <BsMic />}
          </button>
          {showVideoToggle && (
          <button className="text-2xl text-green-500"  onClick={toggleVideo}>
            {isVideoCall ? <IoVideocam /> : <IoVideocamOff />}
          </button>
        )}
        </div>
      </div>

    </div>
  );
};

export default CallingPopup;
