import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  formatDateTime,
} from "../utils/services";
import { encryptMessage, decryptMessage } from "../utils/MessageEncryptDecrypt";
import { getTime } from "../logic/chatweb";

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async ({ chatId, userId }, { rejectWithValue }) => {
    try {
      const response = await getRequest(`/messages/${chatId}`);
      if (!response || response.error) {
        throw new Error(response?.message || "Failed to fetch messages");
      }

      // Transform the response to ensure consistent structure
      return response.map((message) => ({
        _id: message._id,
        msg: decryptMessage(message.message), // Decrypted message
        time: formatDateTime(message.createdAt), // Formatted time
        sent: message.senderId === userId,
        createdAt: message.createdAt,
        senderId: message.senderId,
        senderProfilePicture: message.senderProfilePicture,
        senderName: message.senderName || null,
        isPinned: message.isPinned,
        isDeleted: message.isDeleted,
        files: message.files,
        reactions: message.reactions,
        isForwarded: message.isForwarded,
      }));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



// Send Message
export const sendMessage = createAsyncThunk(
  "messages/sendMessage",
  async ({ chatId, newMessage }, { rejectWithValue, dispatch }) => {
    try {
      const response = await postRequest(`/messages/send/${chatId}`, newMessage);
      if (response && !response.error) {
        dispatch(addMessage(response.data));
        return response.data;
      } else {
        return rejectWithValue(response?.message || "Failed to send message");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete Message
export const deleteMessage = createAsyncThunk(
  "messages/deleteMessage",
  async ({ chatId, messageId, option }, { rejectWithValue }) => {
    const endpoint =
      option === "everyone"
        ? `/messages/conversation/${chatId}/message/${messageId}/for-everyone`
        : option === "me"
        ? `/messages/message/${messageId}/for-me`
        : `/messages/conversation/${chatId}/message/${messageId}`;
    try {
      const result = await deleteRequest(endpoint);
      if (result && !result.error) {
        return { messageId, option, msg: result.message };
      } else {
        return rejectWithValue(result?.message || "Failed to delete message");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Update Message
export const updateMessages = createAsyncThunk(
  "messages/updateMessage",
  async ({ chatId, messageId, newMessageContent }, { rejectWithValue }) => {
    try {
      const response = await putRequest(
        `/messages/conversation/${chatId}/message/${messageId}`,
        { newMessageContent }
      );
      if (response && !response.error) {
        return {
          messageId,
          updatedContent: newMessageContent,
          editedAt: response.updatedMessage.editedAt,
        };
      } else {
        return rejectWithValue(response?.message || "Failed to update message");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Pin Message
export const pinMessage = createAsyncThunk(
  "messages/pinMessage",
  async ({ chatId, messageId }, { rejectWithValue }) => {
    try {
      const response = await postRequest(`/messages/pinMessage/${chatId}`, {
        messageId,
      });
      if (response && !response.error) {
        return { messageId };
      } else {
        return rejectWithValue(response?.message || "Failed to pin message");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Unpin Message
export const unpinMessage = createAsyncThunk(
  "messages/unpinMessage",
  async ({ chatId, messageId }, { rejectWithValue }) => {
    try {
      const response = await postRequest(`/messages/unpinMessage/${chatId}`, {
        messageId,
      });
      if (response && !response.error) {
        return { messageId };
      } else {
        return rejectWithValue(response?.message || "Failed to unpin message");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Favorite Message
export const toggleFavoriteMessage = createAsyncThunk(
  "messages/toggleFavoriteMessage",
  async ({ messageId, isFavorited }, { rejectWithValue }) => {
    try {
      const endpoint = isFavorited
        ? "/messages/removeFavourite"
        : "/messages/setFavourite";
      await postRequest(endpoint, { messageIds: [messageId] });
      return { messageId, isFavorited: !isFavorited };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete Selected Messages
export const deleteSelectedMessages = createAsyncThunk(
  "messages/deleteSelectedMessages",
  async (messageIds, { rejectWithValue }) => {
    try {
      const response = await postRequest("/messages/deleteForMe", messageIds);
      if (response && !response.error) {
        return messageIds;
      } else {
        return rejectWithValue(response?.message || "Failed to delete messages");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const FavoriteMessage = createAsyncThunk(
  "messages/toggleFavorite",
  async ({ messageId, isFavorited }, { rejectWithValue }) => {
    try {
      const endpoint = isFavorited
        ? "/messages/setFavourite"
        : "/messages/removeFavourite";

      await postRequest(endpoint, { messageIds: [messageId] });
      return { messageId, isFavorited };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const sendReplyMessages = createAsyncThunk(
  "messages/sendReplyMessage",
  async ({ encryptedMessageContent, replyingTo, conversationId }, { rejectWithValue }) => {
    try {
      const response = await postRequest(
        `/messages/replyMessage/${conversationId}`,
        {
          message: encryptedMessageContent,
          replyTo: replyingTo ? replyingTo._id : null,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in replyMessage controller:", error);
      return rejectWithValue(error.message);
    }
  }
);
// Message Slice
const messageSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [],
    pinnedMessages: [],
    selectedMessages: [],
    archiveChats: [],
    typing: false,
    replyingTo: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.messages = [];
      state.pinnedMessages = [];
      state.selectedMessages = [];
    },
    setTyping: (state, action) => {
      state.typing = action.payload;
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setReplyingTo: (state, action) => {
      state.replyingTo = action.payload;
    },
    setArchivedChats: (state, action) => {
      state.archiveChats = action.payload;
    },
    setSelectedMessages: (state, action) => {
      const messageId = action.payload;
      if (state.selectedMessages.includes(messageId)) {
        state.selectedMessages = state.selectedMessages.filter(
          (id) => id !== messageId
        );
      } else {
        state.selectedMessages.push(messageId);
      }
    },
    toggleFavoriteMessageState: (state, action) => {
      const messageId = action.payload;
      const message = state.messages.find((msg) => msg._id === messageId);
      if (message) {
        message.isFavorited = !message.isFavorited;
      }
    },
    deleteMessageForMe: (state, action) => {
      const { messageId } = action.payload;
      state.messages = state.messages.filter((msg) => msg._id !== messageId);
    },
    editMessage: (state, action) => {
      const { messageId, newContent, editedAt } = action.payload;
      const message = state.messages.find((msg) => msg._id === messageId);
      if (message) {
        message.msg = newContent;
        message.editedAt = editedAt;
      }
    },
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.messages = action.payload;
        state.loading = false;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.messages.push(action.payload);
        state.loading = false;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(pinMessage.fulfilled, (state, action) => {
        const { messageId } = action.payload;
        state.messages = state.messages.map((msg) =>
          msg._id === messageId ? { ...msg, isPinned: true } : msg
        );
      })
      .addCase(unpinMessage.fulfilled, (state, action) => {
        const { messageId } = action.payload;
        state.messages = state.messages.map((msg) =>
          msg._id === messageId ? { ...msg, isPinned: false } : msg
        );
      })
      .addCase(toggleFavoriteMessage.fulfilled, (state, action) => {
        const { messageId, isFavorited } = action.payload;
        const message = state.messages.find((msg) => msg._id === messageId);
        if (message) {
          message.isFavorited = isFavorited;
        }
      });
  },
});

export const {
  clearMessages,
  setTyping,
  addMessage,
  setMessages,
  setReplyingTo,
  setArchivedChats,
  setSelectedMessages,
  toggleFavoriteMessageState,
  editMessage, 
  deleteMessageForMe, 
} = messageSlice.actions;

export default messageSlice.reducer;