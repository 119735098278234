import React, { useContext, useState } from "react";
import { imgUrl, postArrayRequest } from "../utils/services";
import { ConversationContext } from "../context/ConversationContext";
import { useSelector } from "react-redux";

const ForwardModal = ({
  onClose,
  onForward,
  selectedConversationIds,
  setSelectedConversationIds,
  thisConversation,
}) => {
  const userConversations = useSelector((state) => state.conversation.userConversations);
  const [searchTerm, setSearchTerm] = useState("");

  const handleCheckboxChange = (conversationId) => {
    setSelectedConversationIds((prevSelected) => {
      if (prevSelected.includes(conversationId)) {
        return prevSelected.filter((id) => id !== conversationId);
      } else {
        return [...prevSelected, conversationId];
      }
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-[#202d33] p-4 rounded-lg shadow-lg w-[400px] h-[510px] relative">
        {/* Close Button */}
        <button
          className="absolute top-3 right-3 text-white text-2xl hover:text-gray-400 focus:outline-none"
          onClick={onClose}
        >
          &times;
        </button>

        {/* Title */}
        <h2 className="text-white text-lg mb-4">Forward to...</h2>

        {/* Search Input */}
        <input
          type="text"
          className="w-full p-2 rounded bg-[#111a21] text-white mb-3"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Conversation List */}
        <div className="overflow-y-auto h-[350px] mb-4">
          {userConversations
            .filter(
              (contact) =>
                contact._id !== thisConversation._id &&
                contact.chatName
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase())
            )
            .map((contact) => (
              <div
                key={contact._id}
                className="flex items-center justify-between text-white mb-2 cursor-pointer p-2 hover:bg-[#3c454c] rounded-lg"
              >
                <div className="flex items-center">
                  <img
                    src={`${imgUrl}${
                      contact.chatIcon || "/path/to/default/avatar.jpg"
                    }`}
                    alt="Contact"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  <p>
                    {contact.chatName ||
                      contact.participants[0]?.name ||
                      "Unnamed Chat"}
                  </p>
                </div>
                <input
                  type="checkbox"
                  className="form-checkbox text-emerald-500"
                  checked={selectedConversationIds.includes(contact._id)}
                  onChange={() => handleCheckboxChange(contact._id)}
                />
              </div>
            ))}
        </div>

        <button
          onClick={onForward}
          className="bg-emerald-500 text-white py-2 px-4 rounded w-full"
        >
          Forward
        </button>
      </div>
    </div>
  );
};

export default ForwardModal;
