import React, { useEffect, useState, useContext } from "react";
import {
  AiOutlineClose,
  AiOutlineBlock,
  AiOutlineFlag,
  AiOutlineDelete,
} from "react-icons/ai";
import {
  MdModeEdit,
  MdNotificationsOff,
  MdOutlineMessage,
  MdOutlineLock,
} from "react-icons/md";
import { MdPeopleAlt } from "react-icons/md";
import { AuthContext } from "../context/AuthContext";
import { imgUrl, baseUrl } from "../utils/services";
import AddMembers from "./AddMembers";
import ParticipantsList from "./ParticipantsList";
import ProfileView from "./ProfileView";
import axios from "axios";
import Media from "./Media";
import { postRequest } from "../utils/services";
import { useDispatch, useSelector } from "react-redux";
import { removeAdmins, promoteToAdmins, exitGroup, removeUserFromGroup, updateGroupDescription, addMembersToGroup, setParticipants, setGroupAdmins, setIsDesEditing,setDescription,setChatIcon } from "../redux/group_slice";
import { setSelectedChat } from "../redux/conversationSlice";


const UserDetails = ({ chat, mediaImages, onClose }) => {
  const dispatch = useDispatch();
  const { groupAdmins, participants, isDesEditing,description, chatIcon } = useSelector(
    (state) => state.group
  );
  const {selectedChat } = useSelector((state) => state.conversation);
  const [showMedia, setShowMedia] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.socket);
  const isBlocked = chat?.blockedBy?.some(
    (block) => block.blockedBy === user._id
  );
  useEffect(() => {
    if (selectedChat.chatIcon) {
      dispatch(setChatIcon(selectedChat.chatIcon));
    }
  }, [dispatch,selectedChat]);

  useEffect(() => {
    if (selectedChat?.participants) {
      dispatch(setParticipants(selectedChat.participants));
    }
    if (selectedChat?.groupAdmins) {
      dispatch(setGroupAdmins(selectedChat.groupAdmins));
    }
    if (selectedChat?.description) {
      dispatch(setDescription(selectedChat.description));
    }
  }, [dispatch, selectedChat]);

 

  useEffect(() => {
    dispatch(setParticipants(selectedChat?.participants || []));
    dispatch(setGroupAdmins(selectedChat?.groupAdmins || []));
    dispatch(setChatIcon(selectedChat?.selectedChatIcon || ""));
    dispatch(setDescription(selectedChat?.description || ""));
  }, [dispatch, selectedChat]);

  const handleAddMembers = async (newMembers) => {
    if (newMembers.length === 0) {
      console.error("No members selected to add");
      return;
    }
    dispatch(addMembersToGroup({ conversationId: selectedChat._id, newMembers, requesterId: user._id, }));
  };

  const handleCancel = () => {
     dispatch(setDescription(""));
    setErrorMessage("");
     dispatch(setIsDesEditing(false));
  };

  const handleShowAddMembers = () => {
    setShowAddMembers(true);
  };

  const handleCloseAddMembers = () => {
    setShowAddMembers(false);
  };

  const handleBlockUnblock = async (action) => {
    try {
      if (action === "block") {
        const userToBlockId = selectedChat.participants.find(
          (participant) => participant._id !== user._id
        )._id;

        const response = await postRequest("/conversations/block", {
          conversationId: selectedChat._id,
          userToBlockId,
        });

        if (!response.error) {
          console.log(`Blocked User in Chat _id: ${selectedChat._id}`);
          selectedChat.blockedBy = selectedChat.blockedBy || [];
          selectedChat.blockedBy.push({ blockedBy: user._id });

          dispatch(setSelectedChat(selectedChat));

        }
      } else if (action === "unblock") {
        const userToUnblockId = chat.participants.find(
          (participant) => participant._id !== user._id
        )._id;

        const response = await postRequest("/conversations/unblock", {
          conversationId: selectedChat._id,
          userToUnblockId,
        });

        if (!response.error) {
          console.log(`Unblocked User in Chat _id: ${selectedChat._id}`);
          selectedChat.blockedBy = selectedChat.blockedBy.filter(
            (block) => block.blockedBy !== user._id
          );
          dispatch(setSelectedChat(selectedChat));
        }
      }
    } catch (error) {
      console.error(`Error during ${action}:`, error.message);
    }
  };

  const handleMuteNotifications = () => {
    console.log("Mute Notifications clicked");
  };

  const handleDisappearingMessages = () => {
    console.log("Disappearing Messages clicked");
  };

  const handleEncryption = () => {
    console.log("Encryption clicked");
  };

  const handleShowMedia = () => {
    setShowMedia(true);
  };

  const handleCloseMedia = () => {
    setShowMedia(false);
  };

  const promoteToAdmin = async (userId) => {
    if (!groupAdmins.includes(user._id)) {
      console.error("You do not have permission to promote users.");
      return;
    }
    dispatch(
      promoteToAdmins({ conversationId: selectedChat._id, adminIds: [userId], requesterId: user._id })
    );
  };

  useEffect(() => {
    if (socket && selectedChat?._id) {
      console.log("Joining chat room:", selectedChat._id);
      socket.emit("joinRoom", selectedChat._id);

      return () => {
        console.log("Leaving chat room:", selectedChat._id);
        socket.emit("leaveRoom", selectedChat._id);
      };
    }
  }, [socket, selectedChat?._id]);

  useEffect(() => {
    const handleGroupAdminsUpdate = ({ conversationId, groupAdmins }) => {
      console.log("Received groupAdminsUpdated socket:", {
        conversationId,
        groupAdmins,
      });
      if (chat._id === conversationId) {
        dispatch(setGroupAdmins(groupAdmins));
      }
    };

    if (socket) {
      if (!socket.connected) {
        console.error("Socket not connected");
      }
      socket.on("groupAdminsUpdated", handleGroupAdminsUpdate);
      return () => {
        socket.off("groupAdminsUpdated", handleGroupAdminsUpdate);
      };
    }
  }, [socket, selectedChat._id]);

  const handleDescriptionSave = async () => {
    setErrorMessage("");

    if (!description.trim()) {
      setErrorMessage("Description cannot be empty.");
      return;
    }

    dispatch(updateGroupDescription({  conversationId: selectedChat._id,  description,requesterId: user._id}));

  };

  const removeUser = async (userId) => {
    if (!groupAdmins.includes(user._id)) {
      console.error(
        "Unauthorized: You do not have permission to remove users."
      );
      return;
    }

    dispatch(
      removeUserFromGroup({ conversationId: selectedChat._id,  membersToRemove: [userId],  requesterId: user._id})
    );
  };

  const handleExitGroup = async () => {
    dispatch(
      exitGroup({ conversationId: selectedChat._id, userId: user._id, participants, groupAdmins,requesterId: user._id, })
    );
  };

  const removeAdmin = async (adminId) => {
    if (!groupAdmins.includes(user._id)) {
      console.error("Unauthorized: You do not have permission to remove admins.");
      return;
    }

    dispatch(
      removeAdmins({ conversationId: selectedChat._id, adminId: adminId,  requesterId: user._id })
    );
  };  


  return (
    <div className="flex flex-col h-full p-4 bg-[#202d33] text-white">
      {showMedia ? (
        <Media images={mediaImages} onBack={handleCloseMedia} />
      ) : (
        <>
          <button
            className="text-white mb-4 self-start text-xl"
            onClick={onClose}
          >
            <AiOutlineClose />
          </button>
          <div className="flex-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 space-y-4">
            {selectedChat.isGroupChat ? (
              <>
                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <div className="flex flex-col items-center mb-4">
                    <ProfileView
                      chat={selectedChat}
                      user={user}
                      groupAdmins={groupAdmins}
                      setChatIcon={setChatIcon}
                      baseUrl={baseUrl}
                    />
                  </div>
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <span
                    className="flex gap-3 text-slate-300 text-xl cursor-pointer"
                    onClick={() =>  dispatch(setIsDesEditing(!isDesEditing))}
                  >
                    <h2 className="text-sm text-green-400 mb-2">
                      Add Group Description
                    </h2>
                    <MdModeEdit />
                  </span>
                  {isDesEditing ? (
                    <div className="flex flex-col gap-2">
                      <textarea
                        value={description}
                        onChange={(e) => dispatch(setDescription(e.target.value))}
                        className="p-2 text-gray-100 rounded-md bg-slate-800 border-b  border-green-300 bg-transparent"
                        placeholder="Enter group description"
                      />
                      {errorMessage && (
                        <div className="text-red-500 text-sm">
                          {errorMessage}
                        </div>
                      )}
                      <div className="flex justify-items-center gap-14 ml-16 mr-16">
                        <button
                          className="bg-red-500 text-white text-sm p-1 rounded-md"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-green-500 text-white text-sm p-2 rounded-md"
                          onClick={handleDescriptionSave}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {description ? (
                        <p className="text-sm text-gray-300">{description}</p>
                      ) : (
                        <div>
                          <p className="text-sm text-gray-300">
                            Created by {selectedChat.createdBy?.username} on{" "}
                            {new Date(selectedChat.createdAt).toLocaleDateString()}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                  <p className="text-sm text-gray-300">{selectedChat.user}</p>
                  <p className="text-sm text-gray-300">{selectedChat.about}</p>
                </div>
                <div className="bg-[#1c2730] p-4 rounded-lg">
                  {groupAdmins.includes(user._id) && (
                    <button
                      className="mt-2 text-gray-100 flex items-center"
                      onClick={handleShowAddMembers}
                    >
                      <div className="bg-green-500 rounded-full p-3 flex items-center justify-center">
                        <MdPeopleAlt className="text-white text-2xl" />
                      </div>
                      <span className="ml-2">Add Members</span>
                    </button>
                  )}

                  {showAddMembers && (
                    <AddMembers
                      currentMembers={participants}
                      onClose={handleCloseAddMembers}
                      onAddMembers={handleAddMembers}
                      chatId={selectedChat._id}
                      user={user}
                    />
                  )}
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2 className="text-xl font-semibold mb-2">Participants</h2>
                  <ParticipantsList
                    participants={participants}
                    groupAdmins={groupAdmins}
                    onPromote={promoteToAdmin}
                    onRemoveAdmin={removeAdmin}
                    onRemove={removeUser}
                    currentUserId={user._id}
                  />
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2
                    className="text-xl font-semibold mb-2 cursor-pointer"
                    onClick={handleShowMedia}
                  >
                    Media
                  </h2>
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2
                    className="text-xl font-semibold mb-2 cursor-pointer text-red-500"
                    onClick={handleExitGroup}
                  >
                    Exit Group
                  </h2>
                </div>
              </>
            ) : (
              <>
                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <div className="flex flex-col items-center mb-4">
                    <img
                      src={`${imgUrl}${selectedChat?.chatIcon}`}
                      alt="profile_picture"
                      className="rounded-full w-[100px] h-[100px] mb-4"
                    />
                    <h1 className="text-2xl font-medium mb-2">
                      {selectedChat.chatName}
                    </h1>
                    <p className="text-sm text-gray-400 mb-2">{selectedChat.mobile}</p>
                  </div>
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2 className="text-xl font-semibold mb-2">Options</h2>
                  <div className="flex flex-col space-y-2">
                    <div
                      className="flex items-center text-red-500 cursor-pointer"
                      onClick={() =>
                        handleBlockUnblock(isBlocked ? "unblock" : "block")
                      }
                    >
                      <AiOutlineBlock className="mr-2" />
                      <span>
                        {isBlocked ? "Unblock Contact" : "Block Contact"}
                      </span>
                    </div>
                    <div
                      className="flex items-center text-red-500 cursor-pointer"
                      onClick={handleMuteNotifications}
                    >
                      <MdNotificationsOff className="mr-2" />
                      <span>Mute Notifications</span>
                    </div>
                    <div
                      className="flex items-center text-red-500 cursor-pointer"
                      onClick={handleEncryption}
                    >
                      <MdOutlineLock className="mr-2" />
                      <span>Encryption</span>
                    </div>
                  </div>
                </div>

                {/* <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2 className="text-xl font-semibold mb-2">More Options</h2>
                  <div className="flex flex-col space-y-2">
                    <div
                      className="flex items-center text-red-500 cursor-pointer"
                      onClick={handleMuteNotifications}
                    >
                      <MdNotificationsOff className="mr-2" />
                      <span>Mute Notifications</span>
                    </div>
                    <div className="flex items-center text-red-500 cursor-pointer" onClick={handleDisappearingMessages}>
                      <MdOutlineMessage className="mr-2" />
                      <span>Disappearing Messages</span>
                    </div>
                    <div
                      className="flex items-center text-red-500 cursor-pointer"
                      onClick={handleEncryption}
                    >
                      <MdOutlineLock className="mr-2" />
                      <span>Encryption</span>
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetails;
