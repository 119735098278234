import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function DeleteConfirmationDialog({ onConfirm, onCancel, isSent }) {
  const [deleteOption, setDeleteOption] = useState("");

  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      className="relative bg-[#2a2f32] rounded-lg shadow-lg max-w-sm w-full p-6 text-white mx-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <h2 className="text-lg font-semibold mb-2">Delete message?</h2>
      <p className="text-xs mb-4">
        You can delete messages for everyone or just for yourself.
      </p>

      <div className="space-y-3 mb-4">
        <label className="flex items-center text-base">
          <input
            type="radio"
            name="deleteOption"
            value="me"
            checked={deleteOption === "me"}
            onChange={() => setDeleteOption("me")}
            className="form-radio text-emerald-500 bg-gray-800 mr-2"
          />
          <span>Delete for me</span>
        </label>
        {isSent && (
          <label className="flex items-center text-base">
            <input
              type="radio"
              name="deleteOption"
              value="everyone"
              checked={deleteOption === "everyone"}
              onChange={() => setDeleteOption("everyone")}
              className="form-radio text-emerald-500 bg-gray-800 mr-2"
            />
            <span>Delete for everyone</span>
          </label>
        )}
      </div>

      <div className="flex justify-center space-x-2 -mb-2">
        <button
          onClick={() => onConfirm(deleteOption)}
          disabled={!deleteOption}
          className={`px-5 py-1.5 rounded-md text-gray-300 ${
            deleteOption
              ? "bg-[#008069] hover:bg-[#006955]"
              : "bg-gray-600 cursor-not-allowed"
          }`}
        >
          Delete
        </button>
        <button
          onClick={onCancel}
          className="px-5 py-1.5 bg-gray-500 text-gray-300 rounded-md hover:bg-gray-400"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default DeleteConfirmationDialog;

// import React from "react";

// function DeleteConfirmationDialog({ onConfirm, onCancel, isSent }) {
//   return (
//     <div
//       className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-md shadow-lg"
//       style={{ backgroundColor: "#000000" }}
//     >
//       <div className="p-2 text-white">
//         {isSent ? (
//           <>
//             <button
//               onClick={() => onConfirm("me")}
//               className="block px-4 py-2 hover:bg-slate-800 w-full text-left"
//             >
//               Delete for Me
//             </button>
//             <button
//               onClick={() => onConfirm("everyone")}
//               className="block px-4 py-2 hover:bg-slate-800 w-full text-left"
//             >
//               Delete for Everyone
//             </button>
//             <button
//               onClick={onCancel}
//               className="block px-4 py-2 hover:bg-slate-800 w-full text-left"
//             >
//               Cancel
//             </button>
//           </>
//         ) : (
//           <>
//             <button
//               onClick={() => onConfirm("me")}
//               className="block px-4 py-2 hover:bg-slate-800 w-full text-left"
//             >
//               Delete for Me
//             </button>
//             <button
//               onClick={onCancel}
//               className="block px-4 py-2 hover:bg-slate-800 w-full text-left"
//             >
//               Cancel
//             </button>
//           </>
//         )}
//       </div>
//     </div>
//   );
// }
