import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/authSlice";
import socketReducer from "../redux/socketSlice";
import conversationReducer from "../redux/conversationSlice";
import groupReducer from "../redux/group_slice";
import messagesReducer from "../redux/message_slice";
import {thunk} from "redux-thunk";
const store = configureStore({
  reducer: {
    auth: authReducer,
    socket: socketReducer,
    conversation: conversationReducer,
    group:groupReducer,
    messages:messagesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore non-serializable values like Socket instances
        ignoredActions: ["socket/setSocket", "socket/disconnectSocket"],
        ignoredPaths: ["socket.socket"],
      },
      
    }).concat(thunk),
  
});

export default store;
