import React, { useState } from "react";
import { MdDelete, MdStarOutline } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import ForwardModal from "./ForwardModal";
import { postArrayRequest } from "../utils/services";

const SelectHeader = ({
  onCancel,
  onDelete,
  selectedCount,
  thisConversation,
  selectedMessages,
  onFavoriteMessages,
}) => {
  const [isForwardModalOpen, setIsForwardModalOpen] = useState(false);
  const [selectedConversationIds, setSelectedConversationIds] = useState([]);

  const handleForwardClick = () => {
    setIsForwardModalOpen(true);
  };

  const handleForwardMessage = async () => {
    if (selectedConversationIds.length === 0) {
      alert("Please select at least one conversation to forward the messages.");
      return;
    }

    try {
      const response = await postArrayRequest("/messages/forward", {
        messageIds: selectedMessages,
        conversationIds: selectedConversationIds,
      });

      if (response.success) {
        console.log(
          "Messages forwarded successfully:",
          response.forwardedMessages
        );
      } else {
        console.error("Failed to forward messages:", response.error);
      }
    } catch (error) {
      console.error("Error forwarding messages:", error);
    }

    setIsForwardModalOpen(false);
    onCancel();
  };

  const handleFavoriteSelectedMessages = async () => {
    if (selectedMessages.length === 0) return;

    try {
      const response = await postArrayRequest("/messages/setFavourite", {
        messageIds: selectedMessages,
      });

      if (response && response.success) {
        console.log("Messages favorited successfully.");
        onFavoriteMessages(selectedMessages);
      } else {
        console.error("Failed to favorite messages:", response.error);
      }
    } catch (error) {
      console.error("Error favoriting messages:", error);
    }
    onCancel();
  };

  return (
    <>
      <div className="flex justify-between items-center bg-[#202d33] h-[60px] px-4">
        <div className="flex items-center">
          <p className="text-white mr-4">{selectedCount} selected</p>
        </div>

        <div className="flex items-center space-x-6">
          <MdStarOutline
            className="text-white text-xl cursor-pointer"
            onClick={handleFavoriteSelectedMessages}
          />
          <RiShareForwardFill
            className="text-white text-xl cursor-pointer"
            onClick={handleForwardClick}
          />
          <MdDelete
            className="text-white text-xl cursor-pointer"
            onClick={onDelete}
          />
          <button
            className="bg-[#2a3942] text-white px-2 py-1 rounded-md hover:bg-[#404b52] transition duration-200"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>

      {isForwardModalOpen && (
        <ForwardModal
          onClose={() => setIsForwardModalOpen(false)}
          onForward={handleForwardMessage}
          selectedConversationIds={selectedConversationIds}
          setSelectedConversationIds={setSelectedConversationIds}
          thisConversation={thisConversation}
        />
      )}
    </>
  );
};
export default SelectHeader;
