// import React, { useContext } from 'react';
// import { Routes, Route, Navigate } from 'react-router-dom';
// import Signup from './pages/SingUpPage';
// import Login from './pages/LoginPage';
// import ChatWeb from './pages/ChatWeb';
// import { AuthContext } from './context/AuthContext'; 
// import "bootstrap/dist/css/bootstrap.min.css";
// function App() {
//   const { isAuthenticated } = useContext(AuthContext); 

//   return (
//     <Routes>
//       <Route path="/" element={<Navigate to="/login" />} />
//       <Route path="/signup" element={!isAuthenticated ? <Signup /> : <Navigate to="/login" />} />
//       <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/chatweb" />} />
//       <Route path="/chatweb" element={isAuthenticated ? <ChatWeb /> : <Navigate to="/login" />} />
//     </Routes>
//   );
// }

// export default App;

// import React, { useEffect } from "react";
// import { Routes, Route, Navigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { initializeSocket } from "./redux/socketSlice";
// import { fetchUserConversations, fetchUsersWithoutConversation } from "./redux/conversationSlice";
// //import Signup from "./pages/SignUpPage";
// import Signup from './pages/SingUpPage';
// import Login from "./pages/LoginPage";
// import ChatWeb from "./pages/ChatWeb";
// import "bootstrap/dist/css/bootstrap.min.css";

// const App = () => {
//   const dispatch = useDispatch();
//   const { user, isAuthenticated } = useSelector((state) => state.auth);
//   const socket = useSelector((state) => state.socket.socket);

//   // Initialize socket and fetch user conversations if authenticated
//   useEffect(() => {
//     if (user) {
//       dispatch(initializeSocket(user._id));
//       dispatch(fetchUserConversations(user._id));
//       dispatch(fetchUsersWithoutConversation());
//     }
//   }, [user, dispatch]);

//   return (
//     <Routes>
//       <Route path="/" element={<Navigate to="/login" />} />
//       <Route path="/signup" element={!isAuthenticated ? <Signup /> : <Navigate to="/chatweb" />} />
//       <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/chatweb" />} />
//       <Route path="/chatweb" element={isAuthenticated ? <ChatWeb /> : <Navigate to="/login" />} />
//     </Routes>
//   );
// };

// export default App;



import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initializeSocket } from "./redux/socketSlice";
import { fetchUserConversations, fetchUsersWithoutConversation } from "./redux/conversationSlice";
import Signup from "./pages/SingUpPage"; // Ensure the file name is correct
import Login from "./pages/LoginPage";
import ChatWeb from "./pages/ChatWeb";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  // Initialize socket and fetch user conversations if authenticated
  useEffect(() => {
    if (user) {
      dispatch(initializeSocket(user._id)); // Thunk action to initialize socket
      dispatch(fetchUserConversations(user._id)); // Fetch user conversations
      dispatch(fetchUsersWithoutConversation()); // Fetch other users
    }
  }, [user, dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route
        path="/signup"
        element={!isAuthenticated ? <Signup /> : <Navigate to="/chatweb" />}
      />
      <Route
        path="/login"
        element={!isAuthenticated ? <Login /> : <Navigate to="/chatweb" />}
      />
      <Route
        path="/chatweb"
        element={isAuthenticated ? <ChatWeb /> : <Navigate to="/login" />}
      />
    </Routes>
  );
};

export default App;
