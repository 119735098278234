import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import defaultProfilePic from "../assets/images/cs1.png";
import { FaAngleDown } from "react-icons/fa6";
import DropDownMenu from "./Common/DropDownMenu";
import { postRequest } from "../utils/services";
import { TiPin } from "react-icons/ti";
import { AuthContext } from "../context/AuthContext";
///import { ConversationContext } from "../context/ConversationContext";
import {handleChatAction,setSelectedChat,fetchUserConversations } from "../redux/conversationSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/authSlice";
function Chat({
  pp,
  convo,
  contact,
  msg = [],
  time,
  unreadMsgs,
  active,
  onClick,
  context,
  isGroupChat,
}) {
  const profilePic = pp ? pp : defaultProfilePic;
  const isContactsList = context === "contactsList";

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
  });
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const fetchRefresh = () => {
    dispatch(fetchUserConversations(user._id));
   };
   const handleChatSelect = (chat) => {
    dispatch(setSelectedChat(chat));
    fetchRefresh();
   };
  const dropdownRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  }, []);

  const truncateLastMsgs = (msg, limit = 30) => {
    return msg.length > limit ? `${msg.slice(0, limit)}...` : msg;
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisible, handleClickOutside]);

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setDropdownPosition({ top: event.clientY, left: event.clientX });
    setDropdownVisible(!dropdownVisible);
  };

  const handleDropdownAction = async (action) => {
    try {
      if (action === "pin") {
        const response = await postRequest("/conversations/pin", {
          conversationId: convo._id,
        });
        if (!response.error) {
          console.log(`Pinned Chat _id: ${convo._id}`);
        }
      } else if (action === "unpin") {
        const response = await postRequest("/conversations/unpin", {
          conversationId: convo._id,
        });
        if (!response.error) {
          console.log(`Unpinned Chat _id: ${convo._id}`);
        }
      } else if (action === "archive") {
        const response = await postRequest("/conversations/archive", {
          userId: user._id,
          conversationId: convo._id,
        });
        if (!response.error) {
          console.log(`Archived Chat _id: ${convo._id}`);
        }
      } else if (action === "unarchive") {
        const response = await postRequest("/conversations/unarchive", {
          userId: user._id,
          conversationId: convo._id,
        });
        if (!response.error) {
          console.log(`Unarchived Chat _id: ${convo._id}`);
        }
      } else if (action === "mute") {
        console.log(`Mute Chat _id: ${convo._id}`);
      } else if (action === "delete") {
        console.log(`Delete Chat _id: ${convo._id}`);
      } else if (action === "markAsRead") {
        console.log(`Mark as read Chat _id: ${convo._id}`);
      } else if (action === "block") {
        const userToBlockId = convo.participants.find(
          (participant) => participant._id !== user._id
        )._id;

        const response = await postRequest("/conversations/block", {
          conversationId: convo._id,
          userToBlockId,
        });

        if (!response.error) {
          console.log(`Blocked User in Chat _id: ${convo._id}`);
          convo.blockedBy = convo.blockedBy || [];
          convo.blockedBy.push({ blockedBy: user._id });
          handleChatSelect(convo);
        }
        console.log("userToBlock: ", userToBlockId);
        console.log("conversation: ", convo);
      } else if (action === "unblock") {
        const userToUnblockId = convo.participants.find(
          (participant) => participant._id !== user._id
        )._id;

        const response = await postRequest("/conversations/unblock", {
          conversationId: convo._id,
          userToUnblockId,
        });

        if (!response.error) {
          console.log(`Unblocked User in Chat _id: ${convo._id}`);
          convo.blockedBy = convo.blockedBy.filter(
            (block) => block.blockedBy !== user._id
          );
          handleChatSelect(convo);
        }
      }
      fetchRefresh();
    } catch (error) {
      console.error(`Error during ${action}:`, error.message);
    }
  };

  const isPinned = convo?.pinnedBy?.includes(user?._id);
  const isArchived = convo?.archivedBy?.includes(user?._id);

  const isBlocked = convo?.blockedBy?.some(
    (block) => block.blockedBy === user?._id
  );

  const dropdownOptions = useMemo(() => {
    const options = [
      isPinned
        ? { label: "Unpin chat", onClick: () => handleDropdownAction("unpin") }
        : { label: "Pin chat", onClick: () => handleDropdownAction("pin") },
      isArchived
        ? {
            label: "Unarchive",
            onClick: () => handleDropdownAction("unarchive"),
          }
        : { label: "Archive", onClick: () => handleDropdownAction("archive") },
      // {
      //   label: "Mute notification",
      //   onClick: () => handleDropdownAction("mute"),
      // },
      // { label: "Delete chat", onClick: () => handleDropdownAction("delete") },
      {
        label: "Mark as read",
        onClick: () => handleDropdownAction("markAsRead"),
      },
    ];

    if (!isGroupChat) {
      options.push(
        isBlocked
          ? { label: "Unblock", onClick: () => handleDropdownAction("unblock") }
          : { label: "Block", onClick: () => handleDropdownAction("block") }
      );
    }

    return options;
  }, [isPinned, isArchived, isBlocked, convo]);

  // Time formatting
  const formatTime = (time) => {
    if (!time || time === "N/A") return null;
    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minutes} ${ampm}`;
  };

  const formattedTime = formatTime(time);

  return (
    <div
      className={`flex justify-between items-center cursor-pointer w-100 h-[85px] px-3 hover:bg-[#202d33] ${
        active ? "bg-[#202d33]" : ""
      }`}
      onClick={dropdownVisible ? (e) => e.stopPropagation() : onClick}
    >
      <img
        src={profilePic}
        alt="profile-picture"
        className={`rounded-full w-[50px] mr-5 ${
          isContactsList ? "border border-gray-600" : ""
        }`}
      />

      <div
        className={`flex justify-between w-100 h-100 py-3 ${
          isContactsList ? "" : "border-t border-neutral-700"
        }`}
      >
        <div className="flex flex-col justify-center text-white">
          <h1 className="font-medium mb-1">{contact}</h1>
          <p
            className={`text-sm ${
              !unreadMsgs ? "text-neutral-400" : "text-gray-300"
            }`}
          >
            {truncateLastMsgs(msg)}
          </p>
        </div>

        <div className="flex flex-col justify-between items-end h-100 text-xs">
          {formattedTime && (
            <p className="text-emerald-500 min-w-[55px]">{formattedTime}</p>
          )}

          <div className="flex justify-between items-center px-1">
            {unreadMsgs > 0 && (
              <div className="flex justify-center items-center bg-emerald-500 rounded-full w-[20px] h-[20px] mr-2">
                <p className="text-emerald-900">{unreadMsgs}</p>
              </div>
            )}

            {isPinned && (
              <div className="flex justify-center items-center text-base mr-2 text-emerald-500">
                <TiPin />
              </div>
            )}

            {!isContactsList && (
              <div
                className="flex justify-center items-center hover:bg-slate-600 hover:rounded-full w-5 h-5 text-neutral-400"
                ref={dropdownRef}
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="flex justify-center items-center w-full h-full"
                  onClick={handleDropdownClick}
                >
                  <FaAngleDown />
                </button>
              </div>
            )}

            {dropdownVisible && (
              <DropDownMenu
                options={dropdownOptions}
                position={dropdownPosition}
                onClose={() => setDropdownVisible(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;