import React, { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { imgUrl } from "../utils/services";

const ParticipantsList = ({ participants, groupAdmins, onPromote, onRemove, onRemoveAdmin, currentUserId }) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleDropdownToggle = (userId) => {
    setDropdownOpen(dropdownOpen === userId ? null : userId);
  };

  const handlePromote = (userId) => {
    onPromote(userId);
    setDropdownOpen(null);
  };

  const handleRemove = (userId) => {
    onRemove(userId);
    setDropdownOpen(null);
  };

  const handleRemoveAdmin = (userId) => {
    onRemoveAdmin(userId);
    setDropdownOpen(null);
  };

  const sortedParticipants = [...participants].sort((a, b) => {
    const aIsAdmin = groupAdmins.includes(a._id);
    const bIsAdmin = groupAdmins.includes(b._id);

    if (aIsAdmin && !bIsAdmin) return -1;
    if (!aIsAdmin && bIsAdmin) return 1;
    const aUsername = a.username || "";
    const bUsername = b.username || "";

    return aUsername.localeCompare(bUsername);
  });

  return (
    <div>
      {sortedParticipants.map((participant, index) => (
        <div key={participant._id || `participant-${index}`} className="flex items-center justify-between p-3 border-b border-gray-700">
          <div className="flex items-center">
            <img
              src={`${imgUrl}${participant.profilePicture || '/assets/images/default_profile.jpg'}`}
              alt="profile_picture"
              className="rounded-full w-[40px] h-[40px] mr-2"
            />
            <div className="flex flex-col">
              <span className="text-white font-medium">{participant.username}</span>
              <span className="text-xs text-gray-400">{participant.mobile || 'N/A'}</span>
            </div>
          </div>
          <div className="relative">
            {groupAdmins.includes(participant._id) && (
              <span className="text-xs text-white font-sans bg-[#4c5d69] px-2 py-1 rounded">Group admin</span>
            )}
            {groupAdmins.includes(currentUserId) && (
              <button
                className={`ml-2 ${dropdownOpen === participant._id ? 'text-green-500' : 'text-white'}`}
                onClick={() => handleDropdownToggle(participant._id)}
              >
                {dropdownOpen === participant._id ? <AiOutlineUp /> : <AiOutlineDown />}
              </button>
            )}
            {dropdownOpen === participant._id && (
              <div className="absolute right-4 -mt-5 w-40 p-2 bg-gray-900 text-white rounded z-50 shadow-lg">
                {!groupAdmins.includes(participant._id) && (
                  <div
                    key={`${participant._id}-promote`}
                    className="p-2 text-sm cursor-pointer hover:bg-gray-700"
                    onClick={() => handlePromote(participant._id)}
                  >
                    Promote to Admin
                  </div>
                )}
                {groupAdmins.includes(participant._id) && currentUserId !== participant._id && (
                  <div
                    key={`${participant._id}-remove-admin`}
                    className="p-2 text-sm cursor-pointer hover:bg-gray-700"
                    onClick={() => handleRemoveAdmin(participant._id)}
                  >
                    Dismiss as admin
                  </div>
                )}
                <div
                  key={`${participant._id}-remove`}
                  className="p-2 text-sm cursor-pointer hover:bg-gray-700"
                  onClick={() => handleRemove(participant._id)}
                >
                  Remove from Group
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ParticipantsList;

