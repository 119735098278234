import React, { useRef, useEffect, useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa6";
import {
  RiShareForwardFill,
  RiDeleteBin5Line,
  RiUnpinLine,
} from "react-icons/ri";
import { PiCheckSquareBold } from "react-icons/pi";
import { MdOutlineModeEdit, MdOutlinePushPin } from "react-icons/md";
import { HiOutlineArrowUturnLeft } from "react-icons/hi2";

function MessageDialog({
  onClose,
  messageType,
  onEdit,
  onDelete,
  onReact,
  onPin,
  isPinned,
  onForward,
  onReply,
  isSent,
  messageId,
  isForwarded,
  setIsSelectMode,
  position,
  isFavorited,
  onFavoriteToggle,
  isEditable,
}) {
  const dialogRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleReactionClick = (reaction) => {
    onReact(reaction);
    onClose();
  };

  return (
    <div
      ref={dialogRef}
      className={`absolute z-50 border rounded-lg shadow-lg py-2 px-1 ${
        isSent ? "left-0" : "right-0"
      }`}
      style={{
        top: position.top + 50,
        left: isSent ? position.left - 160 : position.left + 160,
        background: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(6px)",
        minWidth: "210px",
        maxWidth: "180px",
      }}
    >
      <button
        onClick={() => {
          onReply(messageId);
          onClose();
        }}
        className="flex items-center px-3 py-2 text-white hover:bg-[#111A21] w-full text-left"
      >
        <HiOutlineArrowUturnLeft className="mr-2" /> Reply
      </button>
      <hr className="text-white" />
      <button
        onClick={onForward}
        className="flex items-center px-3 py-2 text-white hover:bg-[#111A21] w-full text-left"
      >
        <RiShareForwardFill className="mr-2" /> Forward
      </button>
      <button
        onClick={onFavoriteToggle}
        className="flex items-center px-3 py-2 text-white hover:bg-[#111A21] w-full text-left"
      >
        {isFavorited ? (
          <>
            <FaStar className="mr-2" /> Unstar
          </>
        ) : (
          <>
            <FaRegStar className="mr-2" /> Star
          </>
        )}
      </button>
      <hr className="text-white" />
      {isSent && messageType === "text" && !isForwarded && isEditable && (
        <button
          onClick={onEdit}
          className="flex items-center px-3 py-2 text-white hover:bg-[#111A21] w-full text-left"
        >
          <MdOutlineModeEdit className="mr-2" /> Edit
        </button>
      )}

      <button
        onClick={() => onPin(messageId)}
        className="flex items-center px-3 py-2 text-white hover:bg-[#111A21] w-full text-left"
      >
        {isPinned ? (
          <>
            <RiUnpinLine className="mr-2" /> Unpin
          </>
        ) : (
          <>
            <MdOutlinePushPin className="mr-2" /> Pin
          </>
        )}
      </button>
 
      <button
        onClick={onDelete}
        className="flex items-center px-3 py-2 text-white hover:bg-[#111A21] w-full text-left"
      >
        <RiDeleteBin5Line className="mr-2" /> Delete
      </button>
      <hr className="text-white" />
      <button
        className="flex items-center px-3 py-2 text-white hover:bg-[#111A21] w-full text-left"
        onClick={() => {
          setIsSelectMode(true);
        }}
      >
        <PiCheckSquareBold className="mr-2" /> Select
      </button>
      <hr className="text-white" />

      {/* Reaction Emoji Section */}
      <div className="flex items-center justify-around mt-2 mb-1">
        {["👍", "❤️", "😂", "😮", "👎"].map((reaction) => (
          <button
            key={reaction}
            onClick={() => handleReactionClick(reaction)}
            className="text-xl text-white hover:bg-gray-700 rounded-full p-1"
            style={{ lineHeight: 1 }}
          >
            {reaction}
          </button>
        ))}
      </div>
    </div>
  );
}

export default MessageDialog;
