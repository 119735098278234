import React, { useState, useRef, useEffect } from "react";
import Wavesurfer from 'wavesurfer.js';
import { imgUrl, } from "../utils/services";
import { FaStop, FaPlay, } from "react-icons/fa";

const VoiceMessage = (
    userId,
    senderId,
    conversationId,
    msg,
    message,
    profileImage,
) => {
    const [audioMessage, setAudioMessage] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentPlaybackTime, setCurrentPlaybackTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);

    const waveFormRef = useRef(null);
    const waveform = useRef(null);

    useEffect(() => {

        if (waveform.current === null) {
            waveform.current = Wavesurfer.create({
                container: waveFormRef.current,
                waveColor: '#ccc',
                progressColor: '#4a9eff',
                cursorColor: "#7ae3c3",
                barWidth: 2,
                height: 30,
                responsive: true,
            });

            waveform.current.load(message);
            waveform.current.on("ready", () => {
                setTotalDuration(waveform.current.getDuration());
            });

            waveform.current.on("audioprocess", () => {
                setCurrentPlaybackTime(waveform.current.getCurrentTime());
            });
            waveform.current.on("finish", () => {
                setIsPlaying(false);
            });
        }

        return () => {
            // Cleanup Wavesurfer instance
            if (waveform.current) {
                try {
                    // waveform.current.destroy();
                } catch (error) {
                    console.error("Error during Wavesurfer cleanup:", error);
                } finally {
                    waveform.current = null;
                }
            }
        };
    }, [message]);


    useEffect(() => {
        const audioURL = message
        const audio = new Audio(audioURL)
        setAudioMessage(audio)
        waveform.current.load(audioURL)
        waveform.current.on("ready", () => {
            setTotalDuration(waveform.current.getDuration());
        });
    }, [msg])

    useEffect(() => {
        if (audioMessage) {
            const updatePlaybackTime = () => {
                setCurrentPlaybackTime(audioMessage.currentTime);
            };
            audioMessage.addEventListener("timeupdate", updatePlaybackTime);
            audioMessage.addEventListener("ended", () => setIsPlaying(false));
            return () => {
                audioMessage.removeEventListener("timeupdate", updatePlaybackTime);
                audioMessage.addEventListener("ended", () => setIsPlaying(false));
            };
        }
    }, [audioMessage]);

    const handlePlayAudio = () => {
        if (audioMessage) {
            waveform.current.stop();
            waveform.current.play();
            audioMessage.play();
            setIsPlaying(true);
        }
    };

    const handlePauseAudio = () => {
        waveform.current.stop();
        audioMessage.pause();
        setIsPlaying(false);
    };

    const formatTime = (time) => {
        if (isNaN(time)) return "00:00";
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
    };
    // ${message.senderId === conversationId
    //     ? "bg-lime-950"
    //     : "bg-lime-600"
    // }


    return (
        <div className={`flex items-center gap-5 text-white px-2 pr-2 text-sm rounded-md 
            `}
        >
            <div className="cursor-pointer text-xl">
                {
                    !isPlaying ? (
                        <FaPlay onClick={handlePlayAudio} />
                    ) : (
                        <FaStop onClick={handlePauseAudio} />
                    )}
            </div>

            <div className="relative">
                <div className="w-60" ref={waveFormRef}>
                    <div className="text-bubble-meta text-[11px] pt-1 flex justify-between absolute bottom-[-22px] w-full ">
                        <span>
                            {formatTime(isPlaying ? currentPlaybackTime : totalDuration)}
                        </span>
                        <div className="flex gap-1">
                            <span></span>
                        </div>

                    </div>
                </div>
            </div>
        </div>);
};

export default VoiceMessage;