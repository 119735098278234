import React, { useContext, useEffect, useRef, useState } from "react";
import Message from "./MessageBuble";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import SearchPopup from "./SearchPopup";
import AttachmentDialog from "./AttachmentDialog";
import Contacts from "./ContactShareDialog";
import PinnedMessageDialog from "./PinnedMessageDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import { getTime } from "../logic/chatweb";
import { v4 as uuidv4 } from "uuid";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  formatDateTime,
  baseUrl,
} from "../utils/services";
import { encryptMessage, decryptMessage } from "../utils/MessageEncryptDecrypt";
import SelectHeader from "./SelectHeader";
import axios from "axios";
import notificationSound from "../assets/notification.mp3";
import { useDispatch,useSelector } from "react-redux";
import { fetchMessages, deleteMessage,sendMessage,updateMessages,clearMessages,setTyping,addMessage,toggleFavoriteMessage,setPinnedMessages,setMessages,addmessages,setSelectedMessages,
  setReplyingTo,pinMessage,unpinMessage,deleteSelectedMessages, editMessage, // Added import
  deleteMessageForMe, 
 } from "../redux/message_slice";
import { initializeSocket } from "../redux/socketSlice";

function ChatDetail({
  onProfileClick,
  selectedChat,
  updateChatList,
  addNewChat,
  isMobile,
  onBackClick,
}) {
  const dispatch = useDispatch();
  const { messages, selectedMessages,typing,replyingTo,pinnedMessages } = useSelector((state) => state.messages);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [showProfilePicDialog, setShowProfilePicDialog] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [showContactsList, setShowContactsList] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const[conversationId,setConversationId]=useState(null);
  const [currentPinnedIndex, setCurrentPinnedIndex] = useState(0);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const inputRef = useRef(null);
  const bottomRef = useRef(null);
  const chatDetailsRef = useRef(null);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.socket);
  const notificationAudioRef = useRef(null);
  const [replyingMessageId, setReplyingMessageId] = useState(null);
  const chatId = selectedChat._id;

  useEffect(() => {
    if (!chatId) {
      console.error("Selected chat ID is undefined");
      return;
    }
    dispatch(fetchMessages({ chatId, userId:user._id })); 
  }, [dispatch,chatId, user._id]);

  const handleSelectMessage = (messageId) => {
    dispatch(setSelectedMessages(messageId)); 
  };

  const handleFavoriteMessages = (messageIds) => {
    dispatch(toggleFavoriteMessage(messageIds));
  };

  const toggleFavoriteMessages = () => {
    setShowOnlyFavorites((showOnlyFavorites) => !showOnlyFavorites);
  };

  useEffect(() => {
    if (socket && chatId) {
      socket.emit("joinRoom", chatId);

      socket.on("newMessage", (newMessage) => {
        if (newMessage.chatId === chatId) {
          const formattedMessage = {
            ...newMessage,
            msg: decryptMessage(newMessage.message), // Decrypt if necessary
            time: getTime(newMessage.createdAt),
            sent: newMessage.senderId === user._id,
          };
      
          addMessagestoStore(formattedMessage); 
        }
      });

     // Handle edited messages
      socket.on("messageEdited", (editedMessage) => {
        if (editedMessage.chatId === chatId) {
          dispatch(
            editMessage({
              messageId: editedMessage.messageId,
              newContent: decryptMessage(editedMessage.newMessageContent),
              editedAt: editedMessage.editedAt,
            })
          );
        }
      });

      // Handle deleted messages
      socket.on("messageDeleted", ({ messageId, conversationId }) => {
        if (conversationId === chatId) {
          dispatch(
            deleteMessage({
              messageId,
              placeholder: "This message was deleted",
            })
          );
        }
      });

//      Handle "delete for me" messages
      socket.on("messageDeletedForMe", ({ messageId, chatId: deletedChatId }) => {
        if (deletedChatId === chatId) {
          dispatch(deleteMessageForMe({ messageId }));
        }
      });

      // Handle pinned messages
      socket.on("messagePinned", (pinnedMessage) => {
        if (pinnedMessage.conversationId === chatId) {
          dispatch(
            pinMessage({
              messageId: pinnedMessage.messageId,
              pinExpireAt: pinnedMessage.pinExpireAt,
            })
          );
        }
      });

      // Handle unpinned messages
      socket.on("messageUnpinned", (unpinnedMessage) => {
        if (unpinnedMessage.conversationId === chatId) {
          dispatch(unpinMessage({ messageId: unpinnedMessage.messageId }));
        }
      });

      // Cleanup on component unmount or chat change
      return () => {
        socket.emit("leaveRoom", chatId);
        socket.off("newMessage");
        socket.off("messageEdited");
        socket.off("messageDeleted");
        socket.off("messageDeletedForMe");
        socket.off("messagePinned");
        socket.off("messageUnpinned");
      };
    }
  }, [socket, chatId, dispatch, user._id]);
  // Handle message deletion
  const handleDeleteMessage = async (index, option) => {
    const messageToDelete = messages[index];
    const chatId = selectedChat._id;
    const messageId = messageToDelete._id;
    dispatch(
      deleteMessage({
        chatId,
        messageId,
        option,
        index,
      })
    );
  };

  const updateMessage = async (index, newMessageContent) => {
    const messageToUpdate = messages[index];
    const chatId = selectedChat._id;
    const messageId = messageToUpdate._id;

    dispatch(
      updateMessages({
        chatId,
        messageId,
        newMessageContent,
      })
    );
  };

  const handleInputSubmit = async () => {
    if (!inputRef.current || inputRef.current.value.trim() === "") {
      console.warn("Input is empty or undefined");
      return;
    }
  
    if (!selectedChat || !selectedChat._id) {
      console.error("Selected chat or chat ID is undefined");
      return;
    }
  
    const encryptedMessage = encryptMessage(inputRef.current.value);
    if (!encryptedMessage) {
      console.error("Encryption failed. Message is undefined");
      return;
    }
  
    const newMessage = { message: encryptedMessage };
  
    try {
      await dispatch(sendMessage({ chatId: selectedChat._id, newMessage })).unwrap();
      inputRef.current.value = "";
      inputRef.current.focus();
      dispatch(setTyping(false));
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };
  

  const addMessagestoStore = (msg) => {
    dispatch(addMessage(msg));
  };

  const handlePinMessage = async (index, duration) => {
    const messageToPin = messages[index];
    const chatId = selectedChat._id;
    dispatch(
      pinMessage({
        chatId,
        messageId: messageToPin._id,
        duration,
        index,
      })
    );
  };

  const handleUnpinMessage = async (messageId) => {
    const messageToUnpin = messages.find((msg) => msg._id === messageId);

    if (!messageToUnpin || !messageToUnpin._id) {
      console.error("Message or message ID is missing", messageToUnpin);
      return;
    }
    const chatId = selectedChat._id;
    dispatch(
      unpinMessage({
        chatId,
        messageId: messageToUnpin._id,
      })
    );
  };

  const handleReply = (msg) => {
    dispatch(setReplyingTo(msg));
  };

  const cancelReply = () => {
    dispatch(setReplyingTo(null));
  };

  
  const handleNextPinnedMessage = () => {
    setCurrentPinnedIndex(
      (prevIndex) => (prevIndex + 1) % pinnedMessages.length
    );
  };

  const handlePrevPinnedMessage = () => {
    setCurrentPinnedIndex(
      (prevIndex) =>
        (prevIndex - 1 + pinnedMessages.length) % pinnedMessages.length
    );
  };

  const handleClearChat = () => {
    setShowConfirmationDialog(true);
    setActionType("clearChat");
  };

  const confirmAction = async () => {
    if (actionType === "block") {
      try {
        const response = await postRequest("/conversations/block", {
          conversationId: selectedChat._id,
          userToBlockId: selectedChat.participants.find(
            (participantId) => participantId !== user._id
          ),
        });
        if (!response.error) {
          setIsBlocked(true);
          console.log("Contact blocked successfully.");
        }
      } catch (error) {
        console.error("Error blocking contact:", error.message);
      }
    } else if (actionType === "unblock") {
      try {
        const response = await postRequest("/conversations/unblock", {
          conversationId: selectedChat._id,
          userToUnblockId: selectedChat.participants.find(
            (participantId) => participantId !== user._id
          ),
        });
        if (!response.error) {
          setIsBlocked(false);
          console.log("Contact unblocked successfully.");
        }
      } catch (error) {
        console.error("Error unblocking contact: ", error.message);
      }
    } else if (actionType === "clearChat") {
      try {
        const response = await deleteRequest(
          `/messages/clear/${selectedChat._id}`,
          {}
        );

        if (response.success) {
          console.log("Messages cleared for the user.");
          setMessages((prevMessages) =>
            prevMessages.filter(
              (msg) => msg.clearedFor && !msg.clearedFor.includes(user._id)
            )
          );
        } else {
          console.error("Failed to clear messages:", response.error);
        }
      } catch (error) {
        console.error("Error clearing chat:", error.message);
      }
    }
    setShowConfirmationDialog(false);
  };

  const cancelAction = () => {
    setShowConfirmationDialog(false);
  };

  const handleFileSelect = (files) => {
    files.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      addMessagestoStore({
        msg: isImage ? null : file.name,
        time: getTime(),
        sent: true,
        file: file,
        isImage: isImage,
      });
    });
  };

  const handleSendContacts = (contacts) => {
    contacts.forEach((contact) => {
      addMessagestoStore({
        id: uuidv4(),
        msg: `Shared contact: ${contact}`,
        time: getTime(),
        sent: true,
      });
    });
    setSelectedContacts(contacts);
  };

  const handleSendPoll = (poll) => {
    addMessagestoStore({
      poll: poll,
      time: getTime(),
      sent: true,
    });
  };

  const handleVoiceMessageSubmit = async (audioBlob) => {
    alert("send");
    const token = localStorage.getItem("token");

    if (!token) {
      alert("No authentication token found");
      return;
    }

    if (!audioBlob) {
      console.error("Audio blob is not valid.");
      return;
    }

    console.log("Audio Blob:", audioBlob);
    console.log("Audio Blob Type:", audioBlob.type);

    const formData = new FormData();
    const fileName = `voice-message-${Date.now()}.wav`;
    const fileType = "audio/ogg";// Specify the correct MIME type for your file

    // Ensure audioBlob is properly formatted as a File object
    const audioFile = new File([audioBlob], fileName, { type: fileType });

    console.log('Audio File:', audioFile);
    console.log('Conversation ID:', conversationId);

    formData.append('voice', audioFile);
    formData.append('conversationId', conversationId);

    console.log('FormData:', formData);
    try {
      const conversationId = selectedChat?._id;
      console.log("Conversation ID:", conversationId);

      if (!conversationId) {
        console.error("No conversation ID found");
        return;
      }
      console.log("FormData:", formData.get("audio"));

      const response = await axios.post(
        `${baseUrl}/messages/voice/${conversationId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      console.log("Response:", response);

      if (response.status === 201) {
        const audioURL = data.files[0].url;
        addMessage({
          id: uuidv4(),
          audio: audioURL,
          time: getTime(),
          sent: true,
        });
        console.log("Audio Blob Type:", audioBlob.type);
      } else {
        console.error("Error sending voice message:", data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEmojiSelect = (emoji) => {
    if (inputRef.current) {
      inputRef.current.value += emoji;
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [messages]);

  useEffect(() => {
    if (
      selectedChat?.blockedBy?.some((block) => block.blockedBy === user?._id)
    ) {
      setIsBlocked(true);
    } else {
      setIsBlocked(false);
    }
  }, [selectedChat, user]);

  const handleCheckboxChange = (messageId) => {
    setSelectedMessages((prevSelected) =>
      prevSelected.includes(messageId)
        ? prevSelected.filter((id) => id !== messageId)
        : [...prevSelected, messageId]
    );
  };

  // Handle delete selected messages for the current user
  const handleDeleteSelectedMessages = async () => {
    if (selectedMessages.length === 0) {
      console.warn("No messages selected for deletion");
      return;
    }
    dispatch(deleteSelectedMessages({messageIds: selectedMessages}))
    .unwrap()
    .then(() => {
      console.log("Messages successfully deleted for the user");
    })
    .catch((error) => {
      console.error("Error deleting messages:", error);
    });
  };
 

  return (
    <div className="flex flex-col h-screen relative" ref={chatDetailsRef}>
      <audio ref={notificationAudioRef} src={notificationSound} />
      <div className="absolute top-0 left-0 right-0 z-10">
        {isSelectMode ? (
          <SelectHeader
            onCancel={() => {
              setIsSelectMode(false);
              setSelectedMessages([]);
            }}
            onDelete={() => {
              handleDeleteSelectedMessages();
              setIsSelectMode(false);
            }}
            selectedCount={selectedMessages.length}
            thisConversation={selectedChat}
            selectedMessages={selectedMessages}
            onFavoriteMessages={handleFavoriteMessages}
          />
        ) : (
          <ChatHeader
            selectedChat={selectedChat}
            onProfileClick={onProfileClick}
            setShowSearchPopup={setShowSearchPopup}
            onClearChat={handleClearChat}
            isMobile={isMobile}
            onBackClick={onBackClick}
            onStarredMessagesToggle={toggleFavoriteMessages}
          />
        )}
      </div>

      {showSearchPopup ? (
        <SearchPopup
          onClose={() => setShowSearchPopup(false)}
          messages={messages}
        />
      ) : (
        <>
          {pinnedMessages.length > 0 &&
            pinnedMessages.map((msg, index) => (
              <PinnedMessageDialog
                key={index}
                message={pinnedMessages[currentPinnedIndex]}
                onUnpin={() =>
                  handleUnpinMessage(pinnedMessages[currentPinnedIndex]._id)
                }
                onNext={handleNextPinnedMessage}
                onPrev={handlePrevPinnedMessage}
                isFirst={currentPinnedIndex === 0}
                isLast={currentPinnedIndex === pinnedMessages.length - 1}
              />
            ))}
          <div
            className={`bg-[#0a131a] bg-[url('assets/images/bg.webp')] bg-contain overflow-y-scroll h-full transition-all duration-300 ease-in-out scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800`}
            style={{
              padding: "12px 3%",
              paddingTop: "64px",
              marginTop: pinnedMessages.length > 0 ? "76px" : "0px",
            }}
          >
            {(showOnlyFavorites
              ? messages.filter((msg) => msg.isFavorited)
              : messages
            ).length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <p className="text-gray-500 text-base">No messages</p>
              </div>
            ) : (
              (showOnlyFavorites
                ? messages.filter((msg) => msg.isFavorited)
                : messages
              ).filter((msg) => msg && msg._id)
              .map((msg, index) => {
                const previousMessage = index > 0 ? messages[index - 1] : null;
                return (
                  <div key={msg._id} className="relative flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedMessages.includes(msg._id)}
                      onChange={() => handleSelectMessage(msg._id)}
                      className="custom-checkbox ml-2 mr-2 mt-4"
                      style={{
                        display: isSelectMode ? "block" : "none",
                      }}
                    />
                    <div
                      className={`w-full ${msg.sent ? "flex justify-end" : ""}`}
                    >
                      
                      <Message
                        key={uuidv4()}
                        message={msg}
                        msg={msg.msg}
                        mId={msg._id}
                        poll={msg.poll}
                        time={msg.time}
                        sent={msg.sent}
                        files={msg.files}
                        img={msg.img}
                        audio={msg.audio}
                        reactions={msg.reactions}
                        profileImage={msg.senderProfilePicture}
                        isPinned={msg.isPinned}
                        senderUsername={selectedChat.isGroupChat ? msg.senderName : null}
                        onPinMessage={() => handlePinMessage(index, "24h")}
                        onUnpinMessage={() => handleUnpinMessage(msg._id)}
                        onReplyMessage={() => handleReply(msg)}
                        onDeleteMessage={(option) =>
                          handleDeleteMessage(index, option)
                        }
                        onEditMessage={(newMessage) =>
                          updateMessage(index, newMessage)
                        }
                        previousMessage={previousMessage}
                        setIsSelectMode={setIsSelectMode}
                        thisConversation={selectedChat}
                        
                      />
                    </div>
                  </div>
                );
              })
            )}
            <div ref={bottomRef}></div>
          </div>

          {showAttachmentDialog && (
            <AttachmentDialog
              onFileSelect={handleFileSelect}
              onSendPoll={handleSendPoll}
              onSendContacts={handleSendContacts}
              onClose={() => setShowAttachmentDialog(false)}
              conversationId={selectedChat._id}
            />
          )}

          {showContactsList && (
            <Contacts
              onSelectContacts={handleSendContacts}
              onClose={() => setShowContactsList(false)}
            />
          )}

          {selectedChat?.blockedBy?.some(
            (block) => block.blockedBy === user._id
          ) ? (
            <div className="p-4 text-center text-gray-500">
              You have blocked this contact.
            </div>
          ) : (
            <ChatInput
              onEmojiClick={() => { }}
              onEmojiSelect={handleEmojiSelect}
              onAttachmentToggle={() => setShowAttachmentDialog(true)}
              onInputSubmit={handleInputSubmit}
              onVoiceMessageSubmit={handleVoiceMessageSubmit}
              typing={setTyping}
              inputRef={inputRef}
              replyingTo={replyingTo}
              onCancelReply={cancelReply}
              conversationId={selectedChat?._id}
            />
          )}
        </>
      )}

      {showProfilePicDialog && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-70 z-50">
          <img
            src={profilePic}
            alt="Profile"
            className="max-w-[80%] max-h-[80%] rounded-lg"
          />
        </div>
      )}

      {showConfirmationDialog && (
        <div className="absolute inset-0 flex items-center justify-center z-50">
          <ConfirmationDialog
            title={
              actionType === "clearChat"
                ? "Clear Chat"
                : actionType === "block"
                ? "Block Contact"
                : "Unblock Contact"
            }
            message={
              actionType === "clearChat"
                ? "Are you sure you want to clear the chat?"
                : actionType === "block"
                ? "Are you sure you want to block this contact?"
                : "Are you sure you want to unblock this contact?"
            }
            onConfirm={confirmAction}
            onCancel={cancelAction}
          />
        </div>
      )}
    </div>
  );
}
export default ChatDetail;


  // useEffect(() => {
  //   if (socket && selectedChat?._id) {
  //     fetchMessages();

  //     socket.emit("joinRoom", selectedChat._id);
  //     socket.on("newMessage", (newMessage) => {
  //       if (newMessage.chatId === selectedChat._id) {
  //         setMessages((prevMessages) => {
  //           if (prevMessages.some((msg) => msg._id === newMessage._id)) {
  //             return prevMessages;
  //           }

  //           updateChatList();
  //           if (newMessage.senderId !== user._id) {
  //             if (notificationAudioRef.current) {
  //               notificationAudioRef.current.play();
  //             }
  //           }
  //           return [
  //             ...prevMessages,
  //             {
  //               _id: newMessage._id,
  //               msg: decryptMessage(newMessage.message),
  //               time: getTime(newMessage.createdAt),
  //               sent: newMessage.senderId === user._id,
  //               senderId: newMessage.senderId,
  //               senderProfilePicture: newMessage.senderProfilePicture,
  //               isPinned: newMessage.isPinned,
  //               files: newMessage.files,
  //               isBlocked: newMessage.isBlocked,
  //             },
  //           ];
  //         });
  //       }
  //       addNewChat();
  //     });

  //     socket.on("messageEdited", (editedMessage) => {
  //       if (editedMessage.chatId === selectedChat._id) {
  //         setMessages((prevMessages) =>
  //           prevMessages.map((msg) =>
  //             msg._id === editedMessage.messageId
  //               ? {
  //                   ...msg,
  //                   msg: decryptMessage(editedMessage.newMessageContent),
  //                   editedAt: editedMessage.editedAt,
  //                 }
  //               : msg
  //           )
  //         );
  //       }
  //     });

  //     socket.on("messageDeleted", ({ messageId, conversationId }) => {
  //       if (conversationId === selectedChat._id) {
  //         setMessages((prevMessages) =>
  //           prevMessages.map((msg) =>
  //             msg._id === messageId
  //               ? {
  //                   ...msg,
  //                   msg: "This message was deleted ",
  //                   isDeleted: true,
  //                   file: null,
  //                   audio: null,
  //                   img: null,
  //                 }
  //               : msg
  //           )
  //         );
  //       }
  //     });
  //     socket.on("messageDeletedForMe", ({ messageId, chatId }) => {
  //       if (chatId === selectedChat._id) {
  //         setMessages((prevMessages) =>
  //           prevMessages.filter((msg) => msg._id !== messageId)
  //         );
  //       }
  //     });
  //     socket.on("messagePinned", (pinnedMessage) => {
  //       if (pinnedMessage.conversationId === selectedChat._id) {
  //         setMessages((prevMessages) =>
  //           prevMessages.map((msg) =>
  //             msg._id === pinnedMessage.messageId
  //               ? {
  //                   ...msg,
  //                   isPinned: true,
  //                   pinExpireAt: pinnedMessage.pinExpireAt,
  //                 }
  //               : msg
  //           )
  //         );
  //         const pinnedMessageToAdd = messages.find(
  //           (msg) => msg._id === pinnedMessage.messageId
  //         );
  //         setPinnedMessages((prevPinnedMessages) => [
  //           ...prevPinnedMessages,
  //           pinnedMessageToAdd,
  //         ]);
  //       }
  //     });
  //     socket.on("messageUnpinned", (unpinnedMessage) => {
  //       if (unpinnedMessage.conversationId === selectedChat._id) {
  //         setMessages((prevMessages) =>
  //           prevMessages.map((msg) =>
  //             msg._id === unpinnedMessage.messageId
  //               ? { ...msg, isPinned: false, pinExpireAt: null }
  //               : msg
  //           )
  //         );
  //         setPinnedMessages((prevPinnedMessages) =>
  //           prevPinnedMessages.filter(
  //             (msg) => msg._id !== unpinnedMessage.messageId
  //           )
  //         );
  //       }
  //     });
  //     return () => {
  //       socket.emit("leaveRoom", selectedChat._id);
  //       socket.off("newMessage");
  //       socket.off("messageEdited");
  //       socket.off("messageDeleted");
  //       socket.off("messageDeletedForMe");
  //       socket.off("messagePinned");
  //       socket.off("messageUnpinned");
  //     };
  //   }
  // }, [selectedChat, updateChatList, user._id]);