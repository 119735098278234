export const baseUrl = process.env.REACT_APP_BASEURL;
export const imgUrl = "https://reddy-chat-back-1.onrender.com/";

// Helper to handle JSON responses
// const handleResponse = async (response) => {
//   const data = await response.json();
//   if (!response.ok) {
//     const message = data?.message || "An error occurred...";
//     return { error: true, message };
//   }
//   return data;
// };
const handleResponse = async (response) => {
  try {
    const data = await response.json();
    if (!response.ok) {
      const message = data?.message || "An error occurred...";
      return { error: true, message };
    }
    return data;
  } catch (error) {
    return {
      error: true,
      message: "Failed to parse response",
      details: error.message,
    };
  }
};

// Helper to handle form data requests
const formDataRequest = async (url, method, formData, requiresAuth = true) => {
  const headers = {};

  if (requiresAuth) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }

  const options = {
    method,
    headers,
    body: formData,
    credentials: "include",
  };

  try {
    const response = await fetch(url, options);
    return await handleResponse(response);
  } catch (error) {
    return { error: true, message: error.message };
  }
};

// General request for JSON data
const request = async (url, method, body = null, requiresAuth = true) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (requiresAuth) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }

  const options = {
    method,
    headers,
    credentials: "include",
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);
    return await handleResponse(response);
  } catch (error) {
    return { error: true, message: error.message };
  }
};

// FormData-based POST request
export const postFormDataRequest = async (
  url,
  formData,
  requiresAuth = true
) => {
  return formDataRequest(`${baseUrl}${url}`, "POST", formData, requiresAuth);
};

// Regular JSON-based requests
export const getRequest = async (url, requiresAuth = true) => {
  return request(`${baseUrl}${url}`, "GET", null, requiresAuth);
};

export const postRequest = async (url, body = {}, requiresAuth = true) => {
  return request(`${baseUrl}${url}`, "POST", body, requiresAuth);
};

export const putRequest = async (url, body = {}, requiresAuth = true) => {
  return request(`${baseUrl}${url}`, "PUT", body, requiresAuth);
};

export const deleteRequest = async (url, body = {}, requiresAuth = true) => {
  return request(`${baseUrl}${url}`, "DELETE", body, requiresAuth);
};

export const postArrayRequest = async (
  url,
  arrayBody = [],
  requiresAuth = true
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (requiresAuth) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }

  const options = {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify(arrayBody),
  };

  try {
    const response = await fetch(`${baseUrl}${url}`, options);
    return await handleResponse(response);
  } catch (error) {
    return { error: true, message: error.message };
  }
};

//logging out
// export const logout = async () => {
//   const response = await postRequest("/auth/logout");
//   if (!response.error) {
//     console.log("Successfully logged out");
//     localStorage.removeItem("token");
//   } else {
//     console.error("Logout failed:", response.message);
//   }
//   return response;
// };

export const logout = async () => {
  const response = await postRequest("/auth/logout");

  // Clear localStorage token immediately after logout
  localStorage.removeItem("token");

  if (!response.error) {
    console.log("Successfully logged out");
  } else {
    console.error("Logout failed:", response.message);
  }

  return response;
};

// Date formatter
export const formatDateTime = (isoString) => {
  const options = {
    // day: "2-digit",
    // month: "short",
    // year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return new Date(isoString).toLocaleString("en-US", options);
};
