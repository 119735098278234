import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, postFormDataRequest, postRequest } from "../utils/services";

export const fetchUserConversations = createAsyncThunk(
  "conversation/fetchUserConversations",
  async (userId) => {
    const response = await axios.get(`${baseUrl}/conversations/getConversations/${userId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data.conversations;
  }
);

export const fetchUsersWithoutConversation = createAsyncThunk(
  "conversation/fetchUsersWithoutConversation",
  async () => {
    const response = await axios.get(`${baseUrl}/users/withoutConversation`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  }
);

export const addUserToConversation = createAsyncThunk(
  "conversation/addUserToConversation",
  async ({ userId, selectedUserId }) => {
    const response = await axios.post(
      `${baseUrl}/conversations/addUserToConversation`,
      { userId, selectedUserId },
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
    return response.data;
  }
);


export const createGroup = createAsyncThunk(
  "conversation/createGroup",
  async ({ userId, contacts, groupName, chatIcon }) => {
    const formData = new FormData();
    formData.append("userId", userId);
    contacts.forEach((id) => formData.append("participantIds[]", id));
    formData.append("chatName", groupName);
    if (chatIcon) formData.append("chatIcon", chatIcon.file);

    const response = await postFormDataRequest("/conversations/createGroupConversation", formData);
    return response.conversation;
  }
);


const conversationSlice = createSlice({
  name: "conversation",
  initialState: {
    userConversations: [],
    usersWithoutConversation: [],
    selectedChat: null,
    loading: false,
  },
  reducers: {
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserConversations.fulfilled, (state, action) => {
        state.userConversations = action.payload;
      })
      .addCase(fetchUsersWithoutConversation.fulfilled, (state, action) => {
        state.usersWithoutConversation = action.payload;
      })
      .addCase(addUserToConversation.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.userConversations.push(action.payload);
        }
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.userConversations.push(action.payload);
      })
  },
});
export const selectSelectedChat = (state) => state.conversation.selectedChat;
export const selectUserConversations = (state) =>
  state.conversation.userConversations;
export const { setSelectedChat } = conversationSlice.actions;

export default conversationSlice.reducer;
