// // src/redux/socketSlice.js
// import { createSlice } from "@reduxjs/toolkit";
// import { io } from "socket.io-client";
// import { connectSocket, disconnectSocket as disconnectSocketService, getSocket } from '../utils/socketService';

// const socketSlice = createSlice({
//   name: "socket",
//   initialState: {
//     socket: null,
//     onlineUsers: [],
//   },
//   reducers: {
//     setSocket: (state, action) => {
//       state.socket = action.payload;
//     },
//     setOnlineUsers: (state, action) => {
//       state.onlineUsers = action.payload;
//     },
//     disconnectSocket: (state) => {
//       if (state.socket) {
//         state.socket.disconnect();
//         state.socket = null;
//       }
//     },
//   },
// });

// export const { setSocket, setOnlineUsers, disconnectSocket } = socketSlice.actions;

// export const initializeSocket = (userId) => (dispatch) => {
//   const socket = connectSocket(userId);

//   socket.on("disconnect", (reason) => {
//     console.log(`Socket disconnected: ${reason}`);
//     dispatch(disconnectSocket());
//   });

//   socket.on("onlineUsers", (users) => {
//     dispatch(setOnlineUsers(users));
//   });

//   return () => {
//     socket.disconnect();
//   };
// };

// export default socketSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { io } from "socket.io-client";

const socketSlice = createSlice({
  name: "socket",
  initialState: {
    socket: null,
    onlineUsers: [],
  },
  reducers: {
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload || []; // Prevent undefined payload
    },
    disconnectSocket: (state) => {
      if (state.socket) {
        state.socket.disconnect();
        state.socket = null;
      }
    },
  },
});

export const { setSocket, setOnlineUsers, disconnectSocket } =
  socketSlice.actions;

export const initializeSocket = (userId) => (dispatch, getState) => {
  const { socket } = getState().socket;

  // Prevent multiple socket instances
  if (socket) return;

  const newSocket = io("https://reddy-chat-back-1.onrender.com/", {
    withCredentials: true,
  });

  // Connect socket
  newSocket.on("connect", () => {
    console.log("Connected to socket:", newSocket.id);
    newSocket.emit("userConnected", userId);
  });

  // Handle online users
  newSocket.on("onlineUsers", (users) => {
    dispatch(setOnlineUsers(users));
  });

  // Handle socket disconnect
  newSocket.on("disconnect", () => {
    console.log("Socket disconnected");
    dispatch(disconnectSocket());
  });

  // Save socket instance to Redux
  dispatch(setSocket(newSocket));
};

export default socketSlice.reducer;

// const initialState = {
//   socket: null,
//   onlineUsers: [],
// };

// const socketSlice = createSlice({
//   name: "socket",
//   initialState,
//   reducers: {
//     setSocket: (state, action) => {
//       state.socket = action.payload;
//     },
//     setOnlineUsers: (state, action) => {
//       state.onlineUsers = action.payload;
//     },
//     disconnectSocket: (state) => {
//       if (state.socket) {
//         state.socket.disconnect();
//         state.socket = null;
//       }
//     },
//   },
// });
