import React, { useState, useEffect, useContext, useRef } from "react";
import { MdOutlineAddReaction, MdKeyboardArrowDown } from "react-icons/md";
import { IoCloseCircleSharp } from "react-icons/io5";
import { FaStar, FaDownload, FaMusic } from "react-icons/fa6";
import StatusTicks from "./StatusTicks";
import MessageDialog from "./MessageDialog";
import {
  imgUrl,
  postRequest,
  deleteRequest,
  postArrayRequest,
} from "../utils/services";
import { encryptMessage, decryptMessage } from "../utils/MessageEncryptDecrypt";
import { RiShareForwardFill } from "react-icons/ri";
import {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileImage,
} from "react-icons/fa";
import PollMessage from "./PollMessage";
import { fetchUserConversations, setSelectedChat, selectUserConversations } from "../redux/conversationSlice";
import { AuthContext } from "../context/AuthContext";
import { ConversationContext } from "../context/ConversationContext";
import { format, isValid, differenceInMinutes } from "date-fns";
import { useConversationContext } from "../context/ConversationContext";
import ForwardModal from "./ForwardModal";
import { useSocketContext } from "../context/SocketContext";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { FavoriteMessage } from "../redux/message_slice";
import VoiceMessage from "./VoiceMessage";

function MessageBubble({
  msg = {},
  message,
  mId,
  time,
  isLink,
  img,
  audio,
  senderId,
  sent,
  status,
  profileImage,
  senderUsername,
  onEditMessage,
  onDeleteMessage,
  onPinMessage,
  onReplyMessage,
  replyingTo,
  files = [],
  poll,
  onUnpinMessage,
  isPinned,
  reactions = [],
  previousMessage,
  setIsSelectMode,
  thisConversation,
  conversationId,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(msg);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [isReactionManagementDialogOpen, setIsReactionManagementDialogOpen] = useState(false);
  const [isForwardModalOpen, setIsForwardModalOpen] = useState(false);
  const [selectedConversationIds, setSelectedConversationIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const userConversations = useSelector(selectUserConversations);
  const iconRef = useRef(null);
  const isEditable =
    differenceInMinutes(new Date(), new Date(message.createdAt)) <= 15;
  const [buttonPosition, setButtonPosition] = useState({
    top: 0,
    left: 0,
  });
  const { socket } = useSelector((state) => state.socket);
  const [messageReactions, setMessageReactions] = useState(reactions);
  const currentDate = new Date(message.createdAt);
  const previousDate = previousMessage
    ? new Date(previousMessage.createdAt)
    : null;
  const showDateLabel =
    !previousDate ||
    (isValid(currentDate) &&
      isValid(previousDate) &&
      format(currentDate, "yyyy-MM-dd") !== format(previousDate, "yyyy-MM-dd"));

  const currentUserId = user._id;

  const handleVote = (pollIndex, optionIndex) => {
    console.log(`Poll ${pollIndex} option ${optionIndex} selected`);
  };

  const handleDirectChatSelect = (conversationId) => {
    const chat = userConversations.find((conv) => conv._id === conversationId);
    if (chat) {
      dispatch(setSelectedChat(chat));
    } else {
      console.error("Conversation not found:", conversationId);
    }
  };

  const fetchRefresh = () => {
    dispatch(fetchUserConversations(user._id));
  };

  const handleDeleteClick = () => {
    setIsDeleteConfirmationOpen(true);
    setIsDialogOpen(false);
  };

  const handleReplyClick = () => { };

  const handleDeleteConfirm = (option) => {
    onDeleteMessage(option);
    setIsDeleteConfirmationOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleIconClick = () => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      setButtonPosition({
        top: rect.top + window.scrollY + rect.height,
        left: rect.left + window.scrollX,
      });
    }
    setIsDialogOpen(!isDialogOpen);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleEditMessage = () => {
    setIsEditing(true);
    setIsDialogOpen(false);
  };

  const handleMessageChange = (e) => {
    setEditMessage(e.target.value);
  };

  const handleMessageSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const encryptedMessage = encryptMessage(editMessage);
      onEditMessage(encryptedMessage);
      setIsEditing(false);
    }
  };

  const handleReply = () => {
    onReplyMessage({ id: msg.id, msg });
  };

  const handleProfileClick = () => {
    setIsProfileDialogOpen(true);
  };

  const handleCloseProfileDialog = () => {
    setIsProfileDialogOpen(false);
  };
  //console.log("Message data:", message);
  //console.log("Rendered message data:", message.replyTo);

  useEffect(() => {
    if (socket) {
      socket.on("reactionUpdated", ({ messageId, reaction, userId }) => {
        // console.log("Reaction updated received:", {
        //   messageId,
        //   reaction,
        //   userId,
        // });

        if (messageId === mId) {
          setMessageReactions((prevReactions) => {
            const existingReactionIndex = prevReactions.findIndex(
              (r) => r.userId === userId
            );

            if (existingReactionIndex !== -1) {
              // Update existing reaction
              const updatedReactions = [...prevReactions];
              updatedReactions[existingReactionIndex].reaction = reaction; // Update reaction
              return updatedReactions;
            }

            // If reaction doesn't exist, add it
            //console.log("Adding new reaction for user:", userId);
            return [...prevReactions, { reaction, userId }];
          });
        }
      });

      socket.on("reactionRemoved", ({ messageId, userId }) => {
        // console.log("Reaction removed received:", { messageId, userId });

        if (messageId === mId) {
          setMessageReactions((prevReactions) =>
            prevReactions.filter((r) => r.userId !== userId)
          );
        }
      });

      return () => {
        // console.log("Cleaning up socket listeners");
        socket.off("reactionUpdated");
        socket.off("reactionRemoved");
      };
    }
  }, [socket, mId]);

  const handleReactionAdd = async (reaction) => {
    try {
      const response = await postRequest(`/messages/reaction/${mId}`, {
        reaction,
      });
      // if (!response.error) {
      //   const newReaction = { reaction, userId: currentUserId };
      //   setMessageReactions((prev) => [...prev, newReaction]);
      // }
    } catch (error) {
      console.error("Error adding reaction:", error);
    }
  };

  const handleReactionRemove = async () => {
    try {
      const response = await deleteRequest(`/messages/reaction/${mId}`);
      if (!response.error) {
        setMessageReactions((prev) =>
          prev.filter((r) => r.userId !== currentUserId)
        );
        setSelectedReaction(null);
      }
    } catch (error) {
      console.error("Error removing reaction:", error);
    }
  };

  const handlePinToggle = () => {
    if (isPinned) {
      if (typeof onUnpinMessage === "function") {
        onUnpinMessage(msg._id);
      } else {
        console.error("onUnpinMessage function error.");
      }
    } else {
      if (typeof onPinMessage === "function") {
        onPinMessage();
      } else {
        console.error("onPinMessage function error.");
      }
    }
    setIsDialogOpen(false);
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return <FaFilePdf className="text-red-500" />;
      case "doc":
      case "docx":
        return <FaFileWord className="text-sky-600" />;
      case "xls":
      case "xlsx":
        return <FaFileExcel className="text-green-600" />;
      case "ppt":
      case "pptx":
        return <FaFilePowerpoint className="text-orange-600" />;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        return <FaFileImage className="text-amber-300" />;
      case "mp3":
      case "wav":
      case "ogg":
        return <FaMusic className="text-green-600" />;
      default:
        return <FaFileImage className="text-gray-400" />;
    }
  };

  const handleForwardMessage = async () => {
    if (selectedConversationIds.length === 0) {
      alert("Please select at least one conversation to forward the message.");
      return;
    }

    try {
      // Using postArrayRequest to handle array payloads
      const response = await postArrayRequest("/messages/forward", {
        messageIds: [mId],
        conversationIds: selectedConversationIds,
      });

      if (response.success) {
        console.log(
          "Message forwarded successfully:",
          response.forwardedMessages
        );

        const lastSelectedConversationId =
          selectedConversationIds[selectedConversationIds.length - 1];
        handleDirectChatSelect(lastSelectedConversationId);
      } else {
        console.error("Failed to forward message:", response.error);
      }
    } catch (error) {
      console.error("Error forwarding message:", error);
    }
    fetchRefresh();
    closeAndReset();
  };

  const isImage =
    img || (files.length > 0 && files[0]?.type?.startsWith("image/"));

  const messageType = isImage
    ? "image"
    : files.length > 0
      ? "file"
      : audio
        ? "audio"
        : isLink
          ? "link"
          : poll
            ? "poll"
            : "text";

  const isDeleted = msg && typeof msg === "object" && msg.isDeleted;

  const handleReactionClick = (reaction) => {
    if (reaction.userId === currentUserId) {
      handleReactionRemove();
    } else {
      setSelectedReaction(reaction.reaction);
    }
  };

  const closeAndReset = () => {
    setIsForwardModalOpen(false);
    setSelectedConversationIds([]);
  };

  const forwardMessage = () => {
    setIsForwardModalOpen(true);
    handleCloseDialog();
  };

  const handleFavoriteToggle = async () => {
    try {
      dispatch(
        FavoriteMessage({
          messageId: message._id,
          isFavorited: !message.isFavorited,
        })
      );

    } catch (error) {
      console.error("Error toggling favorite status:", error);

    }
    setIsDialogOpen(false);
  };

  return (
    <React.Fragment>
      {showDateLabel && isValid(currentDate) && (
        <div className="w-full flex justify-center my-2">
          <span className="text-xs text-gray-500">
            {format(currentDate, "dd/MM/yyyy")}
          </span>
        </div>
      )}
      <div
        className={`relative flex flex-col rounded-md p-2 mb-2 ${sent
          ? isImage ||
            files.some((file) =>
              ["mp4", "webm", "ogg"].includes(
                file.filename.split(".").pop().toLowerCase()
              )
            )
            ? "ml-auto"
            : "bg-[#005c4b] ml-auto"
          : isImage ||
            files.some((file) =>
              ["mp4", "webm", "ogg"].includes(
                file.filename.split(".").pop().toLowerCase()
              )
            )
            ? "mr-auto"
            : "bg-[#202d33] mr-auto"
          }`}
        style={{
          width: "fit-content",
          minWidth: files.length > 0 ? "260px" : "100px",
          maxWidth: "380px",
          marginTop: "20px",
        }}
      >
        {message.replyTo && (
          <div
            className="p-1 mb-1 rounded-md  text-sm text-gray-300 cursor-pointer"
            onClick={handleReplyClick}
            style={{
              borderLeft: `4px solid ${sent ? "#34B7F1" : "#4a6fa5"}`,
              backgroundColor: `${sent ? "#3A786B" : "#2a3942"}`,
              marginBottom: "8px",
            }}
          >
            <span className="font-semibold text-gray-100">
              {message.replyTo.senderName || "Unknown"}
            </span>
            <div className="text-xs text-gray-300">
              {decryptMessage(message.replyTo.message)}
            </div>
          </div>
        )}

        {message.isForwarded && (
          <div className="flex items-center gap-1 text-xs text-gray-400 font-medium italic mb-1">
            <div>
              <RiShareForwardFill />
            </div>
            <div>Forwarded</div>
          </div>
        )}

        <div className="relative flex flex-row ">
          {!sent && (
            <div className="relative">
              <img
                src={`${imgUrl}${profileImage}`}
                alt="User Profile"
                className="w-8 h-6 rounded-full mr-2 cursor-pointer"
                onClick={handleProfileClick}
              />
              {isProfileDialogOpen && (
                <div className="absolute top-10 left-0 bg-opacity- p-4 rounded-md shadow-md w-48 z-50">
                  <div className="relative">
                    <img
                      src={`${imgUrl}${profileImage}`}
                      alt="Profile"
                      className="w-24 h-24 rounded-full mx-auto"
                    />
                    <IoCloseCircleSharp
                      className="absolute top-1 right-1 text-red-500 text-xl cursor-pointer"
                      onClick={handleCloseProfileDialog}
                    />
                  </div>
                </div>
              )}

            </div>

          )}
          <div className="flex flex-col space-y-1">
            {!sent && senderUsername && (
              <div className="font-bold text-green-500 text-sm">{senderUsername}</div>
            )}
            <div
              className="flex flex-col w-full h-full mx-2"
              style={{ wordBreak: "break-word" }}
            >
              {isEditing ? (
                <textarea
                  value={editMessage}
                  onChange={handleMessageChange}
                  onKeyDown={handleMessageSubmit}
                  className="w-full p-2 rounded-md text-white bg-[#202D33]"
                  autoFocus
                />
              ) : files.length > 0 ? (
                <div className="flex flex-col items-center">
                  {files.map((file, index) => {
                    const fileUrl = file.url;
                    const fileExtension = file.filename
                      .split(".")
                      .pop()
                      .toLowerCase();

                    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
                      return (
                        <div key={file._id || index} className="relative">
                          <img
                            src={fileUrl}
                            alt={file.filename}
                            className="rounded-md max-w-[270px] w-full h-auto"
                          />
                          <a
                            href={fileUrl}
                            download
                            className="absolute top-0 -right-1 hover:bg-[#004b3b] text-white text-base py-1 px-2 rounded"
                          >
                            <FaDownload />
                          </a>
                        </div>
                      );
                    } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
                      return (
                        <div key={file._id || index} className="relative">
                          <video
                            src={fileUrl}
                            controls
                            className="rounded-md max-w-[270px] w-full h-auto"
                          >
                            Your browser does not support the video tag.
                          </video>
                          <a
                            href={fileUrl}
                            download
                            className="absolute top-1 right-1 hover:bg-[#3C454C] text-white text-base py-1 px-2 rounded"
                          >
                            <FaDownload />
                          </a>
                        </div>
                      );
                    } else if (message.type === "audio" || ["mp4", "mp3", "webm", "wav", "ogg", 'audio/ogg; codecs=opus', "audio/wav"].includes(fileExtension)) {
                      {
                        console.log("Rendering VoiceMessage for file:", fileUrl);
                        return (
                          <VoiceMessage
                            key={file._id || index}
                            message={fileUrl}
                            conversationId={conversationId}
                            userId={currentUserId}
                            profileImage={profileImage}
                            senderId={senderId}
                            msg={msg}
                          />
                        );
                      }
                    }
                    else {
                      console.log("Default case triggered for:", file);
                      return (
                        <div
                          key={file._id}
                          className="flex items-center justify-between rounded-lg py-3 w-full mr-3 "
                        >
                          <div className="text-4xl text-white mr-4">
                            {getFileIcon(file.filename)}
                          </div>

                          <div className="flex flex-col justify-center w-full">
                            <div className="text-white text-sm font-medium mb-2">
                              {file.filename}
                            </div>
                            <a
                              href={fileUrl}
                              download
                              className="block bg-[#33796E] hover:bg-[#004b3b] text-white text-sm font-semibold py-1 px-2 rounded-md w-full text-center"
                              aria-label={`Download ${file.filename}`}
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              ) : poll ? (
                <div className="flex flex-col">
                  <PollMessage
                    poll={poll}
                    onVote={(pollId, optionIndex) =>
                      handleVote(pollId, optionIndex)
                    }
                    sent={sent}
                  />
                </div>
              ) : audio ? (
                <div className="flex flex-col items-start">
                  <audio controls className="rounded-md max-w-[270px] w-300">
                    <source src={audio} type="audio/wav" />
                    <source src={audio} type="audio/mpeg" />
                    <source src={audio.replace('.wav', '.mp3')} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              ) : isLink ? (
                <a
                  href={msg}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline"
                >
                  {msg}
                </a>
              ) : (
                <p
                  className={`text-white text-sm mr-auto ${isDeleted ? "text-gray-500" : ""
                    }`}
                >
                  {isDeleted ? "This message was deleted" : msg}
                </p>
              )}

              {isForwardModalOpen && (
                <ForwardModal
                  onClose={() => closeAndReset()}
                  onForward={handleForwardMessage}
                  selectedConversationIds={selectedConversationIds}
                  setSelectedConversationIds={setSelectedConversationIds}
                  thisConversation={thisConversation}
                />
              )}
            </div>
          </div>
          {!message.isDeleted && (
            <div className="flex flex-row justify-between items-center">
              <div className="flex-grow"></div>
              <div
                className={`absolute flex flex-row -mx-2 mt-2 items-center justify-center cursor-pointer bg-[#202D33] py-2 px-1 rounded-full text-slate-300 hover:bg-slate-700 ${sent
                  ? "-left-12 top-1/2 transform -translate-y-1/2"
                  : "-right-12 top-1/2 transform -translate-y-1/2"
                  }`}
                onClick={handleIconClick}
              >
                <MdOutlineAddReaction className="text-base -my-4" />
                <MdKeyboardArrowDown className="text-sm -my-1" />
              </div>
            </div>
          )}
        </div>

        {messageReactions.length > 0 && (
          <div
            className={`absolute -bottom-0 ${sent ? "-left-2" : "-right-2"
              } text-xl flex`}
            style={{ transform: "translateY(100%)" }}
          >
            {messageReactions.map((reaction) => (
              <div
                // key={reaction._id}
                key={`${reaction.userId}-${reaction.reaction}`}
                className={`text-base cursor-pointer ${sent ? "-ml-1" : "-mr-1"
                  }`}
                onClick={() => handleReactionClick(reaction)}
              >
                {reaction.reaction}
              </div>
            ))}
          </div>
        )}

        {isDialogOpen && (
          <MessageDialog
            isSent={sent}
            onClose={handleCloseDialog}
            onEdit={handleEditMessage}
            onDelete={handleDeleteClick}
            onPinMessage={onPinMessage}
            onForward={forwardMessage}
            onReply={handleReply}
            messageId={msg._id}
            message={{ msg: msg?.content || "", time, sent }}
            onReact={(reaction) => handleReactionAdd(reaction)}
            messageType={messageType}
            onPin={handlePinToggle}
            isPinned={isPinned}
            isFavorited={message.isFavorited}
            onFavoriteToggle={handleFavoriteToggle}
            isForwarded={message.isForwarded}
            setIsSelectMode={setIsSelectMode}
            position={buttonPosition}
            isEditable={isEditable}
          />
        )}
        {isDeleteConfirmationOpen && (
          <DeleteConfirmationDialog
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            isSent={sent}
          />
        )}
        <div className="flex items-center justify-between space-x-2 -mb-2 mt-2">
          {sent && (
            <div className="flex items-center mr-4">
              <StatusTicks status={status} />
            </div>
          )}
          <div className="flex gap-1 text-[10px]">
            {message.isFavorited && (
              <div className="text-yellow-300">
                <FaStar />
              </div>
            )}
            {message.isEdited && (
              <div className="text-[#c0c6cb] -mr-1">Edited</div>
            )}
          </div>
          <div className="text-[#c0c6cb] text-[10px]">{time}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MessageBubble;
