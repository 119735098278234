import React from "react";
import { IoCloseSharp } from "react-icons/io5"; // Close icon

function ReplyManager({ replyingTo, onCancelReply }) {
  return (
    <div className="flex items-center p-2  rounded-t-lg">
      <div className="flex-grow text-sm text-white">
        <strong>Replying to:</strong> {replyingTo?.msg}
      </div>
      <button onClick={onCancelReply} className="text-white">
        <IoCloseSharp />
      </button>
    </div>
  );
}

export default ReplyManager;

// import React from "react";

// function ReplyManager({ replyingTo, onCancelReply }) {
//   if (!replyingTo) return null;

//   return (
//     <div className="bg-[#2c3943] p-2 rounded-t-lg text-white text-sm">
//       <div className="flex items-center">
//         <span className="font-semibold">Replying to:</span>
//         <span className="ml-2">{replyingTo.msg}</span>
//         {/* <button className="ml-auto text-red-500" onClick={onCancelReply}>X</button> */}
//       </div>
//     </div>
//   );
// }

// export default ReplyManager;
