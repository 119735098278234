import React from "react";

const Dropdown = ({ options }) => {
  return (
    <div className="bg-[#202d33] text-white rounded-lg shadow-lg z-10 w-48">
      <ul>
        {options.map((option, index) => (
          <li
            key={index}
            className="p-2 hover:bg-[#3c454c] cursor-pointer"
            onClick={option.onClick}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;

// import React from "react";

// const Dropdown = ({
//   onClearChat,
//   onBlockContact,
//   onUnblockContact,
//   isBlocked,
// }) => {
//   return (
//     <div className="bg-[#202d33] text-white rounded-lg shadow-lg z-10 w-48">
//       <ul>
//         {/* <li
//           className="p-2 hover:bg-[#3c454c] cursor-pointer"
//           onClick={() => {
//             console.log("clear chat");
//             onClearChat();
//           }}
//         >
//           Clear Chat
//         </li> */}
//         <li className="p-2 hover:bg-[#3c454c] cursor-pointer">
//           Mute Notification
//         </li>
//         <li
//           className="p-2 hover:bg-[#3c454c] cursor-pointer flex items-center"
//           onClick={() => {
//             console.log("Block/Unblock clicked");
//             isBlocked ? onUnblockContact() : onBlockContact();
//           }}
//         >
//           {isBlocked ? "Unblock" : "Block"}
//         </li>
//       </ul>
//     </div>
//   );
// };

// export default Dropdown;
