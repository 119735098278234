import React, { useState, useRef, useEffect } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BiHappy } from "react-icons/bi";
import { BsFillMicFill } from "react-icons/bs";
import { MdSend } from "react-icons/md";
import Button from "./Common/RoundedBtn";
import {
  FaPaperclip,
  FaMicrophone,
  FaSmile,
  FaTrash,
  FaPauseCircle,
  FaPlay,
} from "react-icons/fa";
import EmojiPicker from "emoji-picker-react";
import axios from "axios";
import ReplyManager from "./ReplyManager";
import { postRequest } from "../utils/services";
import { encryptMessage } from "../utils/MessageEncryptDecrypt";
import { sendReplyMessages } from "../redux/message_slice";
import { useDispatch } from "react-redux";
import Wavesurfer from 'wavesurfer.js';

function ChatInput({
  conversationId,
  onEmojiClick,
  onEmojiSelect,
  onAttachmentToggle,
  onInputSubmit,
  onVoiceMessageSubmit,
  typing,
  onReply,
  inputRef,
  replyingTo,
  onSubmit,
  onCancelReply,

}) {
  const dispatch = useDispatch();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [waveform, setWaveform] = useState(null);
  const [hasText, setHasText] = useState(false);
  const [isAttachmentActive, setIsAttachmentActive] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [currentPlaybackTime, setCurrentPlaybackTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [renderedAudio, setRenderedAudio] = useState(null);

  const canvasRef = useRef(null);
  const audioRef = useRef(null);
  const waveFormRef = useRef(null)
  const mediaRecorderRed = useRef(null);

  useEffect(() => {
    console.log("Chunks array updated:", chunks);
    if (chunks.length > 0) {
      const audioBlob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
      console.log("Audio Blob created:", audioBlob);

      const url = URL.createObjectURL(audioBlob);
      console.log("Audio URL created:", url);

      setAudioUrl(url);

      const audio = new Audio(url);
      setRecordedAudio(audio);
      console.log("Recorded Audio instance created:", audio);

      waveform.load(url);
      console.log("Waveform loaded with audio URL:", url);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("ended", handleAudioEnd);
        console.log("Removed 'ended' event listener from audioRef");
      }
    };
  }, [chunks]);


  useEffect(() => {
    if (waveFormRef.current) {
      const WaveSurferInstance = Wavesurfer.create({
        container: waveFormRef.current,
        waveColor: '#ccc',
        progressColor: '#4a9eff',
        cursorColor: "#7ae3c3",
        barWidth: 2,
        height: 30,
        responsive: true,
      });
      setWaveform(WaveSurferInstance);

      WaveSurferInstance.on("finish", () => {
        setIsPlaying(false);
      });

      return () => {
        WaveSurferInstance.destroy();
      };
    }
  }, [waveFormRef.current]);


  useEffect(() => {
    if (waveform) startRecording();
  }, [waveform]);

  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    let timer;
    if (isRecording && !isPaused) {
      timer = setInterval(() => {
        setRecordingTime((prev) => {
          setTotalDuration(prev + 1);
          return prev + 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRecording, isPaused]);

  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
    setHasText(true);
    onEmojiClick();
  };

  const handleEmojiSelect = (emojiData) => {
    if (inputRef.current) {
      inputRef.current.value += emojiData.emoji;
      inputRef.current.focus();
      setHasText(inputRef.current.value.length > 0);
    }
  };

  const toggleAttachmentDialog = () => {
    setIsAttachmentActive((prev) => !prev);
    setHasText(true);
    onAttachmentToggle();
  };

  const handleInputChange = () => {
    if (inputRef.current) {
      const inputHasText = inputRef.current.value.length > 0;
      setHasText(inputHasText);
      typing(inputHasText);
    }
  };

  const sendReplyMessage = async (encryptedMessageContent) => {
    console.log("Sending reply with data:", {
      message: encryptedMessageContent,
      replyTo: replyingTo ? replyingTo._id : null,
      conversationId,
    });
    console.log("Conversation ID in sendReplyMessage:", conversationId);

    dispatch(
      sendReplyMessages({
        encryptedMessageContent,
        replyingTo,
        conversationId,
      })
    )
      .unwrap()
      .then((response) => {
        onInputSubmit(response);
        onCancelReply();
      })
      .catch((error) => {
        console.error("Failed to send reply message:", error);
      });

  };

  const handleTextInputSubmit = () => {
    if (inputRef.current && inputRef.current.value) {
      const messageContent = inputRef.current.value;
      const encryptedMessage = encryptMessage(messageContent)
      if (replyingTo) {

        sendReplyMessage(encryptedMessage);
      } else {

        onInputSubmit({ content: encryptedMessage });
      }

      inputRef.current.value = "";
      setHasText(false);
      setShowEmojiPicker(false);
      setIsAttachmentActive(false);
      deleteRecording();
    } else {
      console.error("Input cannot be empty");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleTextInputSubmit();
    }
  };

  const startRecording = () => {
    setChunks([]);
    setIsRecording(true);
    setIsPaused(false);
    setRenderedAudio(null);
    setCurrentPlaybackTime(0);
    setRecordingTime(0);
    setTotalDuration(0);

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          mediaRecorderRed.current = recorder;
          audioRef.current.srcObject = stream;
          setMediaRecorder(recorder);

          recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              setChunks((prevChunks) => [...prevChunks, e.data]);
            }
          };

          recorder.onstop = () => {
            const audioBlob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
            const audioURL = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioURL);

            console.log("Audio Blob created:", audioBlob);
            setRecordedAudio(audio);
            setAudioUrl(audioURL);
          };
          recorder.start();

        })
        .catch((err) => console.error("Error accessing audio devices", err));
    }
  };


  useEffect(() => {
    console.log("Replying To in ChatInput:", replyingTo);
  }, [replyingTo]);

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      waveform.stop();
      if (audioContext) {
        audioContext.close();
        setAudioContext(null);
        setAnalyser(null);
      }
      const audioBlob = new Blob(chunks, { type: "audio/mp3" });
      onVoiceMessageSubmit(audioBlob);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRed.current) {
      mediaRecorderRed.current.stop();
      setIsPaused(true);
      waveform.stop();

      const audioChunks = [];
      mediaRecorderRed.current.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorderRed.current.addEventListener("stop", () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });
        const audioFile = new File([audioBlob], "recording.mp3");
        setRenderedAudio(audioFile);
      });
    }
  };

  const resumeRecording = () => {
    if (isPaused && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          mediaRecorderRed.current = recorder;
          audioRef.current.srcObject = stream;
          setMediaRecorder(recorder);

          recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              setChunks((prevChunks) => [...prevChunks, e.data]);
            }
          };

          recorder.onstop = () => {
            const audioBlob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
            const audioURL = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioURL);

            console.log("Complete recording Blob created:", audioBlob);
            setRecordedAudio(audio);
            setAudioUrl(audioURL);
          };

          recorder.start();
          setIsPaused(false);
          setIsRecording(true);
          console.log("Recording resumed");
        })
        .catch((err) => console.error("Error resuming audio devices", err));
    }
  };


  useEffect(() => {
    if (recordedAudio) {
      const updatePlaybackTime = () => {
        setCurrentPlaybackTime(recordedAudio.currentTime);
      };
      recordedAudio.addEventListener("timeupdate", updatePlaybackTime);
      recordedAudio.addEventListener("ended", () => setIsPlaying(false));
      return () => {
        recordedAudio.removeEventListener("timeupdate", updatePlaybackTime);
        recordedAudio.addEventListener("ended", () => setIsPlaying(false));
      };
    }
  }, [recordedAudio]);

  const playRecording = () => {
    if (recordedAudio) {
      waveform.stop();
      waveform.play();
      recordedAudio.play();
      setIsPlaying(true);
    }
  };

  const pausePlayback = () => {
    waveform.stop();
    recordedAudio.pause();
    setIsPlaying(false);
  };

  const deleteRecording = () => {
    setChunks([]);
    setIsRecording(false);
    setRecordingTime(0);
   // recordedAudio.pause();
    setIsPlaying(false);
  };

  const formatTime = (time) => {
    if (isNaN(time)) return "00:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col bg-[#202d33] w-full">
      {replyingTo && (
        <ReplyManager replyingTo={replyingTo} onCancelReply={onCancelReply} />
      )}

      <div className="flex items-center w-full h-[70px] p-2">
        {!isRecording && (
          <>
            <Button icon={<BiHappy />} onClick={handleEmojiClick} />
            <span className="mr-2">
              <Button
                icon={<AiOutlinePaperClip />}
                onClick={toggleAttachmentDialog}
              />
            </span>
            <input
              type="text"
              placeholder="Type a message"
              className="bg-[#2c3943] rounded-lg outline-none text-sm text-neutral-200 w-[1050%] h-full px-3 placeholder:text-sm placeholder:text-[#8796a1]"
              onChange={handleInputChange}
              ref={inputRef}
              onKeyDown={handleKeyDown}
            />
          </>
        )}

        {isRecording && (
          <>
            <Button
              icon={<FaTrash className="text-gray-300 text-lg" />}
              onClick={deleteRecording}
              className="bg-gray-700 hover:bg-gray-600 rounded-full ml-20 text-gray-300"
            />

            {isPaused && recordedAudio && (
              <>
                <Button
                  icon={isPlaying ? <FaPauseCircle /> : <FaPlay className="text-gray-300 text-sm" />}
                  onClick={isPlaying ? pausePlayback : playRecording}
                  className="bg-gray-700 hover:bg-gray-600 rounded-full ml-4 mr-2 text-gray-300"
                />
              </>
            )}

            <div className="w-60" ref={waveFormRef} hidden={!isPaused} />
            {isPaused && recordedAudio && isPlaying && (
              <span className="text-white ml-3">{formatTime(currentPlaybackTime)}</span>
            )}
            {isPaused && recordedAudio && !isPlaying && (
              <span className="text-white ml-3">{formatTime(totalDuration)}</span>
            )}
            <audio ref={audioRef} hidden />


            <div className="flex items-center mr-8 ml-8">
              {!isPaused && (
                <div className="flex items-center text-red-600 text-lg">
                  <span className="animate-pulse mr-2">Recording</span>
                  <span className="mr-2">{formatTime(recordingTime)}</span>
                </div>
              )}
              <Button
                icon={
                  isPaused ? (
                    <FaMicrophone className="text-red-600" />
                  ) : (
                    <FaPauseCircle className="text-red-600" />
                  )
                }
                onClick={isPaused ? resumeRecording : pauseRecording}
                className="bg-gray-700 hover:bg-gray-600 rounded-full ml-2 text-gray-100"
              />
            </div>
          </>
        )}

        <span className="w-full flex items-center justify-end mr-2">
          {hasText || showEmojiPicker || isAttachmentActive ? (
            <Button icon={<MdSend />} onClick={handleTextInputSubmit} />
          ) : (
            <Button
              icon={isRecording ? <MdSend /> : <BsFillMicFill />}
              onClick={isRecording ? stopRecording : startRecording}
              className={`p-2  rounded-full text-white ${isRecording
                ? "bg-green-500 hover:bg-green-700"
                : "bg-[#2a3137] hover:bg-[#3e4e5a]"
                }`}
            />
          )}
        </span>

        {showEmojiPicker && (
          <div className="absolute bottom-[70px] left-0 right-0 z-50">
            <EmojiPicker
              onEmojiClick={handleEmojiSelect}
              className="w-full max-w-[950px] mx-auto border custom-emoji-picker"
              style={{
                width: "100%",
                maxHeight: "370px",
                overflowY: "auto",
                backgroundColor: "#2a3137",
                borderRadius: "8px",
                padding: "10px",
              }}
            />

            <button
              className="absolute bottom-3 right-12 text-white bg-transparent border-none cursor-pointer z-50"
              style={{ fontSize: "30px" }}
              onClick={() => setShowEmojiPicker(false)}
              aria-label="Close emoji picker"
            >
              &times;
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatInput;