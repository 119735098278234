import React from "react";
import { IoCall } from "react-icons/io5";
import { MdCallEnd } from "react-icons/md";
import { MdVideocam } from "react-icons/md";

const IncomingCall = ({ incomingVoiceCall, onAnswer, onReject }) => {
    return (
        <div className="fixed bottom-10 left-10 bg-white shadow-lg p-4 rounded-md z-50">
            <div className="flex items-center justify-between">
                <div>
                    <h3 className="font-semibold">{incomingVoiceCall.callerName} is calling...</h3>
                    <p className="text-sm text-gray-500">
                        {incomingVoiceCall.callType === "video" ? "Video Call" : "Audio Call"}
                    </p>
                </div>
                <div className="flex space-x-3">
                    <button
                        className="bg-green-500 p-2 rounded-full text-white"
                        onClick={onAnswer}
                    >
                        {incomingVoiceCall.callType === "video" ? <MdVideocam /> : <IoCall />}
                    </button>
                    <button
                        className="bg-red-500 p-2 rounded-full text-white"
                        onClick={onReject}
                    >
                        <MdCallEnd />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IncomingCall;