import React, { useRef, useState } from "react";
import { FcDocument, FcAddImage, FcList, FcAudioFile } from "react-icons/fc";
import PollPopup from "./PollPopup";
import ContactsList from "./ContactShareDialog";
import axios from "axios";
import { baseUrl } from "../utils/services";

const AttachmentDialog = ({
  onClose,
  onFileSelect,
  onSendPoll,
  onSendContacts,
  conversationId,
}) => {
  const [showPollPopup, setShowPollPopup] = useState(false);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const fileInputRef = useRef(null);

  const allowedExtensions = [
    "pdf",
    "doc",
    "docx",
    "txt", // Documents
    "png",
    "jpg",
    "jpeg",
    "gif", // Images
    "mp4",
    "webm",
    "ogg", // Videos
    "mp3",
    "wav",
    "ogg", // Audios
  ];

  const handleActionClick = (action) => {
    if (action === "Document") {
      fileInputRef.current.accept = ".pdf,.doc,.docx,.txt";
      fileInputRef.current.click();
    } else if (action === "Picture & Video") {
      fileInputRef.current.accept = "image/*,video/*";
      fileInputRef.current.click();
    } else if (action === "Audio") {
      fileInputRef.current.accept = "audio/*";
      fileInputRef.current.click();
    } else if (action === "Poll") {
      setShowPollPopup(true);
    } else if (action === "Contact") {
      setShowContactPopup(true);
    } else {
      onClose();
    }
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    if (!files || files.length === 0) return;

    const invalidFiles = files.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return !allowedExtensions.includes(fileExtension);
    });

    if (invalidFiles.length > 0) {
      alert(
        `The following files have unsupported types: ${invalidFiles
          .map((file) => file.name)
          .join(", ")}`
      );
      return;
    }

    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });

      formData.append("message", "");

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/messages/sendFiles/${conversationId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        console.log("Files uploaded successfully: ", response.data);
      } else {
        console.error("Failed to upload files: ", response.data.error);
      }
    } catch (error) {
      console.error("Error uploading files: ", error.message);
    }

    onClose();
  };

  const handleCreatePoll = (poll) => {
    onSendPoll(poll);
    setShowPollPopup(false);
    onClose();
  };

  const handleShareContacts = (contacts) => {
    onSendContacts(contacts);
    setShowContactPopup(false);
    onClose();
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {showPollPopup && (
        <PollPopup
          onClose={() => setShowPollPopup(false)}
          onCreatePoll={handleCreatePoll}
        />
      )}
      {showContactPopup && (
        <ContactsList
          onClose={() => setShowContactPopup(false)}
          onShareContact={handleShareContacts}
        />
      )}
      <div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        onClick={handleOverlayClick}
      ></div>
      <div className="absolute bottom-0 left-0 m-4 bg-black bg-opacity-50">
        <div className="bg-[#1c2730] text-white shadow-lg rounded-lg w-[300px] p-4">
          <h2 className="text-xl font-semibold mb-4">Attachment</h2>
          <ul>
            <li
              className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => handleActionClick("Document")}
            >
              <FcDocument className="mr-2" /> Document
            </li>
            <li
              className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => handleActionClick("Picture & Video")}
            >
              <FcAddImage className="mr-2" /> Picture & Video
            </li>
            <li
              className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => handleActionClick("Audio")}
            >
              <FcAudioFile className="mr-2" /> Audio
            </li>
            {/* <li
              className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => handleActionClick("Poll")}
            >
              <FcList className="mr-2" /> Poll
            </li> */}
          </ul>

          {/* Hidden file input */}
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            multiple
            onChange={handleFileSelect}
          />
        </div>
      </div>
    </>
  );
};

export default AttachmentDialog;

// import React, { useRef, useState } from "react";
// import { FcDocument, FcAddImage, FcList, FcAudioFile } from "react-icons/fc";
// import PollPopup from "./PollPopup";
// import ContactsList from "./ContactShareDialog";
// import axios from "axios";
// import { baseUrl } from "../utils/services";

// const AttachmentDialog = ({
//   onClose,
//   onFileSelect,
//   onSendPoll,
//   onSendContacts,
//   conversationId,
// }) => {
//   const [showPollPopup, setShowPollPopup] = useState(false);
//   const [showContactPopup, setShowContactPopup] = useState(false);
//   const fileInputRef = useRef(null);

//   const handleActionClick = (action) => {
//     if (action === "Document") {
//       fileInputRef.current.accept = ".pdf,.doc,.docx,.txt";
//       fileInputRef.current.click();
//     } else if (action === "Picture & Video") {
//       fileInputRef.current.accept = "image/*,video/*";
//       fileInputRef.current.click();
//     } else if (action === "Audio") {
//       fileInputRef.current.accept = "audio/*";
//       fileInputRef.current.click();
//     } else if (action === "Poll") {
//       setShowPollPopup(true);
//     } else if (action === "Contact") {
//       setShowContactPopup(true);
//     } else {
//       onClose();
//     }
//   };

//   const handleFileSelect = async (event) => {
//     const files = Array.from(event.target.files);
//     if (!files || files.length === 0) return;

//     try {
//       const formData = new FormData();

//       files.forEach((file) => {
//         formData.append("files", file);
//       });

//       formData.append("message", "");

//       const token = localStorage.getItem("token");
//       const response = await axios.post(
//         `${baseUrl}/messages/sendFiles/${conversationId}`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       if (response.status === 201) {
//         console.log("Files uploaded successfully: ", response.data);
//       } else {
//         console.error("Failed to upload files: ", response.data.error);
//       }
//     } catch (error) {
//       console.error("Error uploading files: ", error.message);
//     }

//     onClose();
//   };

//   const handleCreatePoll = (poll) => {
//     onSendPoll(poll);
//     setShowPollPopup(false);
//     onClose();
//   };

//   const handleShareContacts = (contacts) => {
//     onSendContacts(contacts);
//     setShowContactPopup(false);
//     onClose();
//   };

//   const handleOverlayClick = (event) => {
//     if (event.target === event.currentTarget) {
//       onClose();
//     }
//   };

//   return (
//     <>
//       {showPollPopup && (
//         <PollPopup
//           onClose={() => setShowPollPopup(false)}
//           onCreatePoll={handleCreatePoll}
//         />
//       )}
//       {showContactPopup && (
//         <ContactsList
//           onClose={() => setShowContactPopup(false)}
//           onShareContact={handleShareContacts}
//         />
//       )}
//       <div
//         className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
//         onClick={handleOverlayClick}
//       ></div>
//       <div className="absolute bottom-0 left-0 m-4 bg-black bg-opacity-50">
//         <div className="bg-[#1c2730] text-white shadow-lg rounded-lg w-[300px] p-4">
//           <h2 className="text-xl font-semibold mb-4">Attachment</h2>
//           <ul>
//             <li
//               className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
//               onClick={() => handleActionClick("Document")}
//             >
//               <FcDocument className="mr-2" /> Document
//             </li>
//             <li
//               className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
//               onClick={() => handleActionClick("Picture & Video")}
//             >
//               <FcAddImage className="mr-2" /> Picture & Video
//             </li>
//             <li
//               className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
//               onClick={() => handleActionClick("Audio")}
//             >
//               <FcAudioFile className="mr-2" /> Audio
//             </li>
//             {/* <li
//               className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
//               onClick={() => handleActionClick("Poll")}
//             >
//               <FcList className="mr-2" /> Poll
//             </li> */}
//           </ul>

//           {/* Hidden file input */}
//           <input
//             ref={fileInputRef}
//             type="file"
//             style={{ display: "none" }}
//             multiple
//             onChange={handleFileSelect}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default AttachmentDialog;
