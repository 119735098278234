import React, { useState, useEffect } from "react";
import LeftMenu from "../components/LeftMenu";
import ChatDetail from "../components/ChatDetail";
import DefaultPage from "../components/DefaultPage";
import LoadingScreen from "../components/LoadingScreen";
//import { AuthContext } from "../context/AuthContext";
import UserDetails from "../components/UserDetails";
//import "react-modern-drawer/dist/index.css";
//import { ConversationContext } from "../context/ConversationContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserConversations, selectSelectedChat, setSelectedChat } from "../redux/conversationSlice";
function ChatWeb() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const selectedChat = useSelector(selectSelectedChat); 
  const userConversations = useSelector((state) => state.conversation.userConversations);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [selectedChat, setSelectedChat] = useState(null);
  //const { user } = useContext(AuthContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  //const {userConversations,fetchUserConversations,selectedChat,handleChatSelect,} = useContext(ConversationContext);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [chatsUsers, setChatsUsers] = useState([]);

  const addNewChat = () => {
    if (user) dispatch(fetchUserConversations(user._id));
  };


  const updateChatList = () => {
    if (user) dispatch(fetchUserConversations(user._id));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChatSelect = (chat) => {
    dispatch(setSelectedChat(chat));
    if (isMobile) setShowLeftMenu(false);
  };
  const handleProfileClick = () => {
    setShowUserDetails(true);
  };

  const handleCloseUserDetails = () => {
    setShowUserDetails(false);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      if (progress >= 100) setLoading(false);
      else {
        const increment = Math.floor(Math.random() * (10 + 1)) + 7;
        setProgress(progress + increment);
      }
    }, 300);
    return () => clearTimeout(id);
  }, [progress]);

  return (
    <>
      {loading ? (
        <LoadingScreen progress={progress} />
      ) : (
        <div className="w-screen h-screen overflow-hidden">
          <div className="flex h-full bg-[#111a21]">
            {/* Left Menu for larger screens */}
            
            <div className="bg-[#111a21] min-w-[340px] max-w-[500px] w-full h-full">
              <LeftMenu
                onChatSelect={handleChatSelect}
                userEmail={user?.username}
                user={user}
                chatsUsers={userConversations}
              />
            </div>
        
            <div className="flex-1 bg-[#222f35] w-full h-full">
              {selectedChat ? (
                <>
                  <div className="flex-1 h-full">
                    {showUserDetails ? (
                      <UserDetails chat={selectedChat} 
                      onClose={handleCloseUserDetails} />
                    ) : (
                      <ChatDetail
                        onProfileClick={handleProfileClick}
                        selectedChat={selectedChat}
                        updateChatList={updateChatList}
                        addNewChat={addNewChat}
                        isMobile={isMobile}
                        onBackClick={() => {handleChatSelect(null)
                        }} 
                      />
                    )}
                  </div>
                </>
              ) : (
                <DefaultPage />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );

}

export default ChatWeb;
