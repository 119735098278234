import React, { useEffect, useState, useContext } from "react";
import { imgUrl } from "../utils/services";
import ChatGroup from "./ChatGroup";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { baseUrl } from "../utils/services";

const AddMembers = ({ onClose, onAddMembers, currentMembers, chatId, user }) => {
    const [allUsers, setAllUsers] = useState([]);
    const [selectedContactsGroup, setSelectedContactsGroup] = useState([]);


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) throw new Error("User not authenticated");

                const response = await axios.get(`${baseUrl}/users/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (Array.isArray(response.data)) {
                    const filteredUsers = response.data.filter(
                        (user) => !currentMembers.some((member) => member._id === user._id)
                    );
                    setAllUsers(filteredUsers);
                } else {
                    console.error("Expected an array, but received:", response.data);
                    setAllUsers([]);
                }
            } catch (error) {
                console.error("Error fetching users:", error);
                setAllUsers([]);
            }
        };

        fetchUsers();
    }, [currentMembers]);

    const handleSelectContact = (contactId) => {
        setSelectedContactsGroup((prev) =>
            prev.includes(contactId)
                ? prev.filter((id) => id !== contactId)
                : [...prev, contactId]
        );
    };


    const handleAddMembers = async () => {
        if (selectedContactsGroup.length === 0) {
            console.error("No members selected to add");
            return;
        }

        try {
            await onAddMembers(selectedContactsGroup);
            setAllUsers((prevUsers) =>
                prevUsers.filter((user) => !selectedContactsGroup.includes(user._id))
            );
            setSelectedContactsGroup([]);
            onClose();
        } catch (error) {
            console.error("Error adding members:", error);
        }
    };


    const sortedUsers = allUsers.sort((a, b) => {
        const nameA = a.username || "";
        const nameB = b.username || "";
        return nameA.localeCompare(nameB);
    });


    return (
        <div className="flex flex-col p-4 bg-[#202d33] text-white h-full">
            <div className="h-[60px] p-3 flex justify-between items-center z-10">
                <h2 className="text-xl mb-4 p-1">Add Members</h2>
                <button
                    className="text-[#fcfbfa] mb-4 text-xl p-1 hover:bg-[#f60d01]"
                    onClick={onClose}
                >
                    <IoMdClose />
                </button>
            </div>
            <div className="flex flex-col space-y-2 overflow-y-scroll h-full scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
                {sortedUsers.length === 0 ? (
                    <div>No users available</div>
                ) : (
                    sortedUsers.map((user, index) => (
                        <div key={user._id || `user-${index}`} className="flex items-center px-2 py-1">
                            <ChatGroup
                                pp={user.profilePicture
                                    ? `${imgUrl}${user.profilePicture}`
                                    : `https://avatar.iran.liara.run/username?username=${user.username}`}
                                contact={user.username}
                                active={selectedContactsGroup.includes(user._id)}
                                onClick={() => handleSelectContact(user._id)}
                                context="addMembers"
                            />
                            <input
                                type="checkbox"
                                className="ml-4"
                                checked={selectedContactsGroup.includes(user._id)}
                                onChange={() => handleSelectContact(user._id)}
                            />
                        </div>
                    ))
                )}
            </div>
            <div className="flex justify-center gap-6 m-4">
                <button className="bg-green-600 p-2 rounded" onClick={handleAddMembers}>
                    Add Selected Members
                </button>
                <button className="text-gray-100 bg-red-500 p-2 rounded" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default AddMembers;