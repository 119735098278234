import React, { useState, useRef, useEffect } from "react";
import { imgUrl } from "../utils/services";
import axios from "axios";
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  updateChatIcon,
  removeChatIcon,
  renameGroup,
} from "../redux/group_slice";

const ProfileView = ({ chat, user, groupAdmins, setChatIcon, baseUrl }) => {
  const dispatch = useDispatch();
  const {selectedChat } = useSelector((state) => state.conversation);
  const [newIcon, setNewIcon] = useState(null);
  const [showIconDropdown, setShowIconDropdown] = useState(false);
  const [showIconForm, setShowIconForm] = useState(false);
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [newChatName, setNewChatName] = useState(chat.chatName);
  const iconFormRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iconFormRef.current && !iconFormRef.current.contains(event.target)) {
        setShowIconForm(false);
        setShowDropdownOptions(false);
        setShowIconDropdown(false);
      }
    };

    if (showIconForm || showDropdownOptions || showIconDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showIconForm, showDropdownOptions, showIconDropdown]);

  const handleIconChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewIcon(file);
      setErrorMessage('');
    }
  };

  const handleRenameGroup = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/conversations/renameGroup`,
        {
          conversationId: chat._id,
          newChatName: newChatName,
          requesterId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        alert(response.data.message);
      } else {
        throw new Error(response.data.error);
      }
      setEditMode(false);
    } catch (error) {
      console.error("Error renaming group:", error);
      alert("Failed to rename group.");
    }
  };

  const handleUpdateIcon = async () => {
    if (!newIcon) {
      setErrorMessage('Please select a file to upload.');
      console.error("No file selected for upload.");
      return;
    }
    if (newIcon) {
      dispatch(updateChatIcon({ conversationId: selectedChat._id, newIcon: newIcon }))
      .unwrap()
      .then(() => {
      setNewIcon(null);
      setShowIconDropdown(false);
      setShowIconForm(false);
      })
    }  else{
      throw new Error("Failed to update chat icon");
    }
  };

  const handleRemoveIcon = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/conversations/removeChatIcon`,
        {
          conversationId: chat._id,
          requesterId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setChatIcon(response.data.chatIcon);
        alert("Chat icon removed and set to default!");
        setShowDropdownOptions(false);
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.error("Error removing chat icon:", error);
      alert("Failed to remove chat icon.");
    }
  };

  return (
    <div className="bg-[#1c2730] p-4 rounded-lg">
      <div className="flex flex-col items-center mb-4">
        <div
          className={`relative ${showDropdownOptions || showIconForm ? "blur-sm" : ""
            }`}
          onMouseEnter={() => setShowIconDropdown(true)}
          onMouseLeave={() => setShowIconDropdown(false)}
        >
          <img
            src={`${imgUrl}${chat.chatIcon}?${new Date().getTime()}`}
            alt="profile_picture"
            className="rounded-full w-[150px] h-[150px] mb-4 cursor-pointer"
          />
          {showIconDropdown && groupAdmins.includes(user._id) && (
            <div className="absolute inset-0 flex items-center w-[150px] h-[150px] mb-4 shadow-lg z-10 duration-300">
              {!showIconForm ? (
                <button
                  className="rounded-full text-center px-4 py-5 text-lg bg-black bg-opacity-50"
                  onClick={() => {
                    setShowDropdownOptions(!showDropdownOptions);
                    setShowIconDropdown(false);
                  }}
                >
                  Change Group Icon
                </button>
              ) : null}
            </div>
          )}
        </div>

        {showDropdownOptions && groupAdmins.includes(user._id) && (
          <div className="absolute z-20 bg-[#1c2730] rounded-lg shadow-lg mt-12 ml-52" ref={iconFormRef}>
            <button
              className="block text-left w-full px-4 py-2 hover:bg-gray-600"
              onClick={() => {
                setShowIconForm(true);
                setShowDropdownOptions(false);
              }}
            >
              Upload Photo
            </button>
            <button
              className="block text-left w-full px-4 py-2 hover:bg-gray-600"
              onClick={() => {
                setShowImageModal(true);
                setShowDropdownOptions(false);
              }}
            >
              View photo
            </button>
            <button
              className="block text-left w-full px-4 py-2 hover:bg-gray-600"
              onClick={() => {
                handleRemoveIcon();
                alert("Remove Icon clicked!");
                setShowDropdownOptions(false);
              }}
            >
              Remove photo
            </button>
          </div>
        )}

        <div className="flex items-center">
          {editMode ? (
            <input
              type="text"
              value={newChatName}
              onChange={(e) => setNewChatName(e.target.value)}
              className="text-lg font-medium m-2 border-b border-green-300 bg-transparent text-white placeholder-gray-700 p-2"
              placeholder="Enter new chat name"
            />
          ) : (
            <h1 className="text-2xl font-medium m-2">{chat.chatName}</h1>
          )}
          {groupAdmins.includes(user._id) && (
            <FaPen
              className="ml-2 cursor-pointer"
              onClick={() => setEditMode(!editMode)}
            />
          )}
        </div>

        {editMode && (
          <button
            className="bg-green-500 text-black px-4 py-2 rounded mt-2"
            onClick={handleRenameGroup}
          >
            Save
          </button>
        )}

      </div>

      {showIconForm && groupAdmins.includes(user._id) && (
        <div className="mt-4 w-full" ref={iconFormRef}>
          <input
            type="file"
            accept="image/*"
            onChange={handleIconChange}
            className="w-full text-sm text-gray-200 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-green-500 file:text-white hover:file:bg-green-600"
          />
          <button
            className="bg-green-500 text-white px-4 py-2 rounded mt-2 w-full"
            onClick={handleUpdateIcon}
          >
            Update photo
          </button>

          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}

        </div>
      )}

      {showImageModal && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="relative bg-[#1c2730] p-6 rounded-lg max-w-lg w-full text-white">
            <button
              className="absolute top-2 right-2 text-xl"
              onClick={() => setShowImageModal(false)}
            >
              ✕
            </button>
            <img
              src={`${imgUrl}${chat.chatIcon}?${new Date().getTime()}`}
              alt="profile_picture"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileView;
