import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { addUserToConversation } from "../redux/conversationSlice";
import AllContacts from "./AllContacts";
import { imgUrl } from "../utils/services";
import { IoMdCheckmarkCircle } from "react-icons/io";

function SearchResults({ users, onClick }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState([]);

  const handleSelectUser = (selectedUserId) => {
    setSelectedUsersToAdd((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(selectedUserId)) {
        return prevSelectedUsers.filter((id) => id !== selectedUserId);
      } else {
        return [...prevSelectedUsers, selectedUserId];
      }
    });
  };

  const handleFinish = () => {
    selectedUsersToAdd.forEach((selectedUserId) => {
      dispatch(addUserToConversation({ userId: user._id, selectedUserId }));
    });
    setSelectedUsersToAdd([]);
    onClick(); 
  };

  const handleCancel = () => {
    setSelectedUsersToAdd([]);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="bg-[#202d33] h-[60px] p-3 flex justify-between items-center w-[256px] z-10">
        <h2 className="text-white text-lg">Add to Chats</h2>
        <button
          className="text-[#fcfbfa] text-xl p-1 hover:bg-[#f60d01]"
          onClick={onClick}
        >
          <IoMdClose />
        </button>
      </div>

      <div className="flex flex-col p-2 w-[256px] justify-between items-center overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
        {users.length > 0 ? (
          users.map((user) => (
            <div
              key={user._id}
              onClick={() => handleSelectUser(user._id)}
              className="w-full flex justify-center relative"
            >
              <AllContacts
                pp={
                  `${imgUrl}${user.profilePicture}` ||
                  `https://avatar.iran.liara.run/username?username=${user.username}`
                }
                contact={user.username}
                phone={user.mobile}
              />
              {selectedUsersToAdd.includes(user._id) && (
                <div className="absolute top-0 right-0 text-green-500">
                  <IoMdCheckmarkCircle className="text-2xl" />
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-white">No users found</p>
        )}

        {selectedUsersToAdd.length > 0 && (
          <div className="flex justify-between w-full mt-4 pr-8 pl-8">
            <button
              className="bg-red-500 text-white p-2 rounded"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 text-white p-2 rounded"
              onClick={handleFinish}
            >
              Ok
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchResults;
